import { useEffect, useMemo, useState } from 'react';
import { retrieveDepartmentsByType } from '../../actions/departments';
import { useAppState, useAppStateDispatcher } from '../../../lib/react-app-state';
import { Department } from '../../components/pages/TrackingOrderDetailPage/components/TrackingOrderDetailEventCard/component';

export default function useDepartments() {
  const appStateDispatch = useAppStateDispatcher();

  const consigneeDepartments = useAppState<Department[] | undefined>('departments:consignee');
  const [loadingConsignees, setLoadingConsignees] = useState(false);

  const terminalDepartments = useAppState<Department[] | undefined>('departments:terminal');
  const [loadingTerminals, setLoadingTerminals] = useState(false);

  const shippingLineDepartments = useAppState<Department[] | undefined>('departments:shipping_line');
  const [loadingShippingLines, setLoadingShippingLines] = useState(false);

  useEffect(() => {
    if (!consigneeDepartments) {
      setLoadingConsignees(true);
      appStateDispatch(retrieveDepartmentsByType('limited_consignee')).then(() => {
        setLoadingConsignees(false);
      });
    }

    if (!terminalDepartments) {
      setLoadingTerminals(true);
      appStateDispatch(retrieveDepartmentsByType('terminal')).then(() => {
        setLoadingTerminals(false);
      });
    }

    if (!shippingLineDepartments) {
      setLoadingShippingLines(true);
      appStateDispatch(retrieveDepartmentsByType('shipping_line')).then(() => {
        setLoadingShippingLines(false);
      });
    }
  }, []);

  const departmentsById = useMemo(() => {
    const departments: Department[] = [
      ...(consigneeDepartments || []),
      ...(terminalDepartments || []),
      ...(shippingLineDepartments || [])
    ];

    return departments.reduce(
      (acc, department) => {
        acc[department.uuid] = department;
        return acc;
      },
      {} as Record<string, Department>
    );
  }, [consigneeDepartments, terminalDepartments, shippingLineDepartments]);

  return {
    consigneeDepartments,
    terminalDepartments,
    shippingLineDepartments,
    departmentsById,
    loadingDepartments: loadingConsignees || loadingTerminals || loadingShippingLines
  };
}
