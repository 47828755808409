import React, { ChangeEvent, useCallback } from 'react';

import './style.css';

interface InputRowProps {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  error?: string | null;
}

function InputRow({ label, placeholder, value, onChange, error }: InputRowProps) {
  const baseClassName = 'input-row';

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__label`}>{label}</div>
      <input
        className={[`${baseClassName}__input`, error ? `${baseClassName}__input-error` : undefined].join(' ')}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default InputRow;
