import { useEffect, useState } from 'react';

const useSidebarCollapsed = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const updateFn = () => {
      const newWidth = window.innerWidth;
      if (newWidth > 1250) {
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    };

    window.addEventListener('resize', updateFn);

    return () => window.removeEventListener('resize', updateFn);
  }, []);

  return isCollapsed;
};

export default useSidebarCollapsed;
