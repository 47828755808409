import React from 'react';
import ReactSelectDropdown from 'react-select';
import SelectDropdownPill from './components/SelectDropdownPill';
import SelectDropdownClearIndicator from './components/SelectDropdownClearIndicator';
import SelectDropdownMultiOption from './components/SelectDropdownMultiOption';
import SelectDropdownIndicators from './components/SelectDropdownIndicators';
import styles from './style';

interface SelectDropdownProps {
  id?: any;
  isMulti?: boolean;
  placeholder?: string;
  stylesOverlay?: Object;
  options?: Array<{ label: string; value: string | number }>;
  value?: any;
  getOptionLabel?: any;
  getOptionValue?: any;
  onChange?: (selectedOption: any, actionType: any, cProps: SelectDropdownProps) => void;
  testId?: string;
}

function SelectDropdown(props: SelectDropdownProps) {
  const components: Record<string, any> = {
    MultiValue: SelectDropdownPill,
    ClearIndicator: SelectDropdownClearIndicator,
    DropdownIndicator: SelectDropdownIndicators
  };

  if (props.isMulti) {
    components.Option = SelectDropdownMultiOption;
  }

  const handleChange = (selectedOption, actionType) => {
    if (props.onChange) {
      props.onChange(selectedOption, actionType, props);
    }
  };

  return (
    <ReactSelectDropdown
      // @ts-ignore
      testId={props.testId}
      stylesOverlay={props.stylesOverlay}
      closeMenuOnSelect={!props.isMulti}
      hideSelectedOptions={false}
      data-testId={props.testId}
      placeholder={props.placeholder}
      getOptionLabel={props.getOptionLabel}
      getOptionValue={props.getOptionValue}
      isMulti={props.isMulti}
      components={components}
      styles={styles}
      options={props.options}
      onChange={handleChange}
      value={props.value}
    />
  );
}

export default SelectDropdown;
