import React from 'react';
import { useRouteError } from 'react-router-dom';
import containerImage from '../../../../media/images/da-container.png';

import './style.css';

function ErrorBoundary() {
  const baseClassName = 'ErrorBoundary';
  const error = useRouteError() as Error;

  return (
    <div className={baseClassName}>
      <img className={`${baseClassName}__img`} src={containerImage} alt="" />
      <h2>Something went wrong</h2>
      <div className={`${baseClassName}__content`}>
        <details className={`${baseClassName}__details`}>
          {error && error.message}
          <br />
          {error.stack}
        </details>
      </div>
    </div>
  );
}

export default ErrorBoundary;
