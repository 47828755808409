import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useMemo, useState } from 'react';
import { ContainerDigestConfig } from '../../../actions/shipper-preferences';
import Button from '../../shared/Button';
import BetterEmpties from './components/BetterEmpties';
import DailyDigestSettings from './components/DailyDigest/component';
import Updates from './components/Updates/component';

import './style.css';

interface SettingsPageProps {
  onCancelClicked: () => void;
  onSaveClicked: () => Promise<void>;
  betterEmptyEmailAddresses: string[];
  dailyDigestConfig: ContainerDigestConfig | null;
  onBetterEmptyEmailChange: (value: string, index: number) => void;
  onBetterEmptyEmailAdd: () => void;
  onBetterEmptyEmailRemove: (index: number) => void;
  onDigestChange: (key: keyof ContainerDigestConfig, index: number, value: any) => void;
  onDigestAdd: (key: keyof Pick<ContainerDigestConfig, 'recipients' | 'times'>) => void;
  onDigestRemove: (key: keyof Pick<ContainerDigestConfig, 'recipients' | 'times'>, index) => void;
  saveButtonEnabled: boolean;
  isLoading: boolean;
  appointmentUpdates: boolean;
  onToggleAppointmentUpdate: (value: boolean) => void;
}

interface TabInfo {
  id: string;
  display: string;
}

function SettingsPage(props: SettingsPageProps) {
  const baseClassName = 'settings-page';

  const { containerDigestV2, showShipperDashSettingsUpdatesTab } = useFlags();

  const [notificationSettingSelectedTab, setNotificationSettingSelectedTab] = useState<TabInfo>({
    id: '',
    display: ''
  });

  const tabs: TabInfo[] = useMemo(() => {
    let shownTabs = [
      { id: 'dailyDigest', display: 'Digest' },
      { id: 'betterEmpties', display: 'Mark Empty' }
    ];

    if (showShipperDashSettingsUpdatesTab) {
      shownTabs = [
        { id: 'dailyDigest', display: 'Digest' },
        { id: 'betterEmpties', display: 'Mark Empty' },
        { id: 'updates', display: 'Updates' }
      ];
    }

    if (!containerDigestV2) {
      shownTabs.splice(0, 1);
    }

    setNotificationSettingSelectedTab(shownTabs[0]);

    return shownTabs;
  }, [containerDigestV2, setNotificationSettingSelectedTab]);

  let component = (
    <DailyDigestSettings
      dailyDigestConfig={props.dailyDigestConfig}
      onDigestChange={props.onDigestChange}
      onDigestAdd={props.onDigestAdd}
      onDigestRemove={props.onDigestRemove}
    />
  );

  if (notificationSettingSelectedTab.id === 'betterEmpties') {
    component = (
      <BetterEmpties
        emailAddresses={props.betterEmptyEmailAddresses}
        onEmailChange={props.onBetterEmptyEmailChange}
        onEmailAdd={props.onBetterEmptyEmailAdd}
        onEmailRemove={props.onBetterEmptyEmailRemove}
      />
    );
  } else if (notificationSettingSelectedTab.id === 'updates') {
    component = (
      <Updates
        onToggleAppointmentUpdate={props.onToggleAppointmentUpdate}
        appointmentUpdates={props.appointmentUpdates}
      />
    );
  }

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header`}>
        <div>Settings</div>
      </div>
      <div className={`${baseClassName}__main-content-container`}>
        <div className={`${baseClassName}__button-content-container`}>
          Set your notification preferences
          <div className={`${baseClassName}__button-container`}>
            <Button
              className={`${baseClassName}__cancel-button`}
              type="submit"
              label="Cancel"
              color="primary"
              onClick={props.onCancelClicked}
            />
            <Button
              className={`${baseClassName}__save-button`}
              loading={props.isLoading}
              type="submit"
              label="Save"
              color="secondary"
              disabled={!props.saveButtonEnabled}
              onClick={props.onSaveClicked}
              data-testid="settings-save-notification-preferences"
            />
          </div>
        </div>
        <div className={`${baseClassName}__h-6`} />
        <div className={`${baseClassName}__selection-container`}>
          {tabs.map((tabInfo, index) => {
            let className = '';

            if (notificationSettingSelectedTab.id === tabInfo.id) {
              className += `${baseClassName}__selection-container-active `;
            }

            if (index > 0) {
              className += `${baseClassName}__selection-container-margin `;
            }

            return (
              <div
                id={tabInfo.id}
                key={`settings-tab-key-${tabInfo.id}`}
                onClick={() => {
                  setNotificationSettingSelectedTab(tabInfo);
                }}
                className={className}
              >
                {tabInfo.display}
              </div>
            );
          })}
        </div>
        {component}
      </div>
    </div>
  );
}

export default SettingsPage;
