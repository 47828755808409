import { useEffect, useState } from 'react';

import actionEmitter from './app-state-manager';

export default function useAppState<T = any>(path: string): T {
  const [state, setState] = useState(actionEmitter.state[path]);

  useEffect(() => {
    actionEmitter.addActionListener(path, setState);

    return () => {
      actionEmitter.removeActionListener(path, setState);
    };
  }, []);

  return state;
}

// import { useEffect, useState } from 'react';

// import actionEmitter from './app-state-manager';

// export default function useAppState(path: string | string[]) {
//   if (Array.isArray(path)) {
//     let [state, setState] = useState(actionEmitter.state[...path]);

//     useEffect(() => {
//       path.forEach((key) => {
//         actionEmitter.addActionListener(key, (data) => {
//           setState([...]);
//         });
//       });

//       return () => {
//         path.forEach((key) => {
//           actionEmitter.removeActionListener(key, (data) => {
//             setState;
//           });
//         });
//       };
//     }, []);
//   } else {
//     let [state, setState] = useState(actionEmitter.state[path]);

//     useEffect(() => {
//       actionEmitter.addActionListener(path, setState);

//       return () => {
//         actionEmitter.removeActionListener(path, setState);
//       };
//     }, []);
//   }

//   return state;
// }
