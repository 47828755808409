/* eslint-disable func-names */
const historyPushPopOverride = function (type) {
  const orig = history[type];
  return function () {
    // @ts-ignore
    const rv = orig.apply(this, arguments);
    const e = new Event(type);
    (e as any).arguments = arguments;
    window.dispatchEvent(e);
    return rv;
  };
};

history.pushState = historyPushPopOverride('pushState');
history.replaceState = historyPushPopOverride('replaceState');
