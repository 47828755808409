const HOST_VERSIONS = {
  // V1 type host:
  // host accounts in local: localhost
  // host accounts in test: accounts.drayalliance.test
  // host accounts in stage: accounts.drayalliance.stage
  // host accounts in prod: accounts.drayalliance.com
  V1: 'v1',

  // V2 type host:
  // host accounts in local: localhost
  // host accounts in test: accounts.test.drayalliance.com
  // host accounts in stage: accounts.stage.drayalliance.com
  // host accounts in prod: accounts.drayalliance.com
  V2: 'v2'
};

export function getHostVersionAndEnv() {
  const hostParts = window.location.hostname.split('.');
  const isLocal = window.location.hostname.indexOf('localhost') > -1;
  const endsWithCom = window.location.hostname.endsWith('.com');
  let env;

  if (isLocal) {
    return { env: 'local', version: HOST_VERSIONS.V2 };
  }

  if (endsWithCom) {
    if (hostParts.length !== 3) {
      // handle new test/stage domains below
      // eg. accounts.test.drayalliance.com, accounts.stage.drayalliance.com
      env = hostParts[1];
    } else {
      // has to be prod
      env = 'prod';
    }
    return {
      version: HOST_VERSIONS.V2,
      env
    };
  }

  // handle old test/stage
  // eg. accounts.drayalliance.test, accounts.drayalliance.stage
  env = hostParts[hostParts.length - 1];
  return {
    version: HOST_VERSIONS.V1,
    env
  };
}

export function getBaseApiUrlFromVersionAndEnv(env: string, version: string) {
  if (env === 'local') {
    return '';
  }
  if (env === 'prod') {
    return `//api.drayalliance.com`;
  }
  switch (version) {
    case HOST_VERSIONS.V1:
      return `//api.drayalliance.${env}`;
    case HOST_VERSIONS.V2:
      return `//api.${env}.drayalliance.com`;
    default:
      throw new Error('Unhandled host version');
  }
}

export function api(path: string, prefixOverride?: string): string {
  if (path.startsWith('/')) {
    path = path.slice(1);
  }

  const { version, env } = getHostVersionAndEnv();
  const baseApiUrl = getBaseApiUrlFromVersionAndEnv(env, version);

  const prefixArray: string[] = [];
  if (env !== 'local' && !prefixOverride) {
    prefixArray.push('hub');
  } else {
    prefixArray.push('api');
    prefixArray.push('hub');
  }

  let prefixes;
  if (prefixOverride) {
    prefixes = prefixOverride;
  } else {
    prefixes = `/${prefixArray.join('/')}`;
  }

  return `${baseApiUrl}${prefixes}/${path}`;
}

function env() {
  const { env } = getHostVersionAndEnv();
  switch (env) {
    case 'prod':
      return 'prod';
    case 'stage':
      return 'stage';
    default:
      return 'test';
  }
}

export function whitelabel(path: string): string {
  if (path.startsWith('/')) {
    path = path.slice(1);
  }
  return `https://drayalliance-whitelabel.s3-us-west-2.amazonaws.com/${env()}/${path}`;
}
