import React from 'react';
import moment from 'moment-timezone';
import CreateQuote from './components/CreateQuote/component';
import QuoteList from './components/QuoteList/component';
import useDepartments from '/app/utils/hooks/useDepartments';
import { SelfServiceQuote, fetchQuotes } from '../../../../../actions/self-service-quotes';

import './style.css';

export const filterAndSortQuotes = (quotes: SelfServiceQuote[]) => {
  if (quotes.length) {
    const expiredQuotes = quotes
      .filter((quote) => moment(quote.expiredAt).isBefore(moment()))
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

    const activeQuotes = quotes
      .filter((quote) => moment(quote.expiredAt).isAfter(moment()))
      .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));

    return [...activeQuotes, ...expiredQuotes];
  }

  return [];
};

function QuotePage() {
  const baseClassName = 'quote-page';

  const { departmentsById, consigneeDepartments, loadingDepartments } = useDepartments();

  const [loadingQuotes, setLoadingQuotes] = React.useState<boolean>(true);
  const [quotes, setQuotes] = React.useState<SelfServiceQuote[]>([]);

  React.useEffect(() => {
    setLoadingQuotes(true);
    fetchQuotes()
      .then((data) => {
        setQuotes(filterAndSortQuotes(data));
        setLoadingQuotes(false);
      })
      .catch(() => {
        setLoadingQuotes(false);
      });
  }, []);

  const handleQuoteGenerated = (quote: SelfServiceQuote) => {
    const quoteIndex = quotes.findIndex((q) => q.uuid === quote.uuid);

    if (quoteIndex === -1) {
      setQuotes((prevQuotes) => [quote, ...prevQuotes]);
      return;
    }

    const reOrderedQuotes = [...quotes];
    reOrderedQuotes.splice(quoteIndex, 1);
    reOrderedQuotes.unshift(quote);
    setQuotes(reOrderedQuotes);
  };

  if (loadingDepartments) {
    return <div>Loading...</div>;
  }

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header`}>
        <div>Generate a Quote</div>
      </div>

      <div className={`${baseClassName}__main-content-container`}>
        <QuoteList quotes={quotes} loading={loadingQuotes} departmentsById={departmentsById} />
        <div className={`${baseClassName}__separator`} />
        <CreateQuote onQuoteGenerated={handleQuoteGenerated} consigneeDepartments={consigneeDepartments} />
      </div>
    </div>
  );
}

export default QuotePage;
