import React from 'react';
import ExclamationIcon from '/media/icons/fa/svg/solid/exclamation.svg';
import CheckIcon from '/media/icons/fa/svg/solid/check.svg';

import './style.css';

interface MessageProps {
  message: string;
  type?: 'error' | 'success';
  className?: any;
}
function Message({ type, message, className }: MessageProps) {
  let baseClassName = 'message__text-container';

  if (className) {
    baseClassName += ` ${className}`;
  }

  return (
    <div className={baseClassName}>
      {type === 'error' && <ExclamationIcon />}
      {type === 'success' && <CheckIcon />}
      <pre>{message}</pre>
    </div>
  );
}

export default Message;
