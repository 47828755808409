import React, { useCallback } from 'react';
import Select, { SingleValue } from 'react-select';

import './style.css';

interface SelectRowProps {
  label: string;
  options: { label: string; value: string }[];
  value: { label: string; value: string } | null;
  onChange: (value: { label: string; value: string }) => void;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
}

function SelectRow({ label, options, onChange, value, disabled }: SelectRowProps) {
  const baseClassName = 'select-row';

  const handleChange = useCallback(
    (
      value: SingleValue<{
        label: string;
        value: string;
      }>
    ) => {
      if (value) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__label`}>{label}</div>
      <Select
        className={`${baseClassName}__input`}
        options={options}
        value={value}
        onChange={handleChange}
        isDisabled={disabled || false}
      />
    </div>
  );
}

export default SelectRow;
