import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LogoutIcon from '/media/icons/com/logout.svg';
import TrackingIcon from '/media/icons/com/tracking.svg';
import AdminIcon from '/media/icons/fa/svg/light/user-crown.svg';
import ToolsIcon from '/media/icons/fa/svg/light/tools.svg';
import ShipIcon from '/media/icons/fa/svg/light/ship.svg';
import ContainerIcon from '/media/icons/fa/svg/light/truck-container.svg';
import CogIcon from '/media/icons/fa/svg/light/cog.svg';
import WarehouseIcon from '/media/icons/fa/svg/light/warehouse.svg';
import AnalyticsIcon from '/media/icons/fa/svg/light/analytics.svg';
import ContainerStorageIcon from '/media/icons/fa/svg/light/container-storage.svg';
import VanityLogo from '../../../../shared/VanityLogo';
import NameLogoIcon from '/media/icons/com/dray-alliance-logo-black.svg';
import ChartAreaIcon from '/media/icons/fa/svg/light/chart-area.svg';
import DashboardLeftSidebarNavBtn from '../DashboardLeftSidebarNavBtn';
import Tooltip from '../../../../shared/Tooltip';
import useSidebarCollapsed from '../../../../../utils/useSidebarCollapsed';

import './style.css';

export interface DashboardLeftSidebarProps {
  isAdmin?: boolean;
  ordersQuery: Object;
  basePath: string;
  pageId?: string;
  onLogout: () => void;
  subdomain?: string;
  showAnalytics: boolean;
  showDemurrage: boolean;
  showScorecard: boolean;
}

function DashboardLeftSidebar(props: DashboardLeftSidebarProps) {
  const baseClassName = 'app-dashboard-left-sidebar';

  const { showAnalytics, showDemurrage, showScorecard, basePath, isAdmin, ordersQuery, subdomain, onLogout } = props;

  const location = useLocation();
  const pageId = location.pathname.split('/')[2];

  const [showSubAnalytics, setShowSubAnalytics] = useState(pageId !== 'analytics');
  const [showSubSelfService, setShowSubSelfService] = useState(pageId === 'self-service');
  const { betterEmptyReturns, showShipperDashSettingsTab, showShipperSelfService } = useFlags();
  const isCollapsed = useSidebarCollapsed();
  const shouldShowTools = betterEmptyReturns;

  let firstAvailableAnalyticsPage;
  if (showScorecard) {
    firstAvailableAnalyticsPage = 'scorecard';
  } else if (!showScorecard && showDemurrage) {
    firstAvailableAnalyticsPage = 'demurrage';
  }

  const [currentAnalyticsPage, setCurrentAnalyticsPage] = useState(firstAvailableAnalyticsPage);

  let defaultSelfServicePage = 'create-order';
  const [currentSelfServicePage, setCurrentSelfServicePage] = useState(defaultSelfServicePage);

  useEffect(() => {
    let currentSelfServicePage = 'create-order';
    if (location.pathname.includes('self-service/consignee-creation')) {
      currentSelfServicePage = 'consignee-creation';
    } else if (location.pathname.includes('self-service/quote')) {
      currentSelfServicePage = 'quote';
    }

    setCurrentSelfServicePage(currentSelfServicePage);
  }, [location.pathname]);

  function toggleAnalytics() {
    if (showSubAnalytics) {
      setShowSubAnalytics(false);
    } else {
      setShowSubAnalytics(true);
      if (showDemurrage || showScorecard) {
        setCurrentAnalyticsPage(firstAvailableAnalyticsPage);
      }
    }
  }

  function toggleSelfService() {
    if (showSubSelfService) {
      setShowSubSelfService(false);
    } else {
      setShowSubSelfService(true);
    }
  }

  function subAnalyticsOn() {
    setShowSubAnalytics(true);
  }

  function subAnalyticsOff() {
    setShowSubAnalytics(false);
  }

  useEffect(() => {
    if (pageId !== 'self-service' && showSubSelfService) {
      setShowSubSelfService(false);
    }

    if (pageId !== 'analytics' && showSubAnalytics) {
      setShowSubAnalytics(false);
    }
  }, [pageId]);

  const initialWidthIsCollapsed = window.innerWidth < 1250;
  const useCollapsedBehavior = initialWidthIsCollapsed || isCollapsed;

  return (
    <nav className={baseClassName}>
      <div className={`${baseClassName}__header`}>
        <Link
          className={`${baseClassName}__header-logo-btn`}
          to={`${basePath}/tracking/orders${ordersQuery ? `?${queryString.stringify(ordersQuery)}` : ''}`}
        >
          {useCollapsedBehavior && (
            <VanityLogo className={`${baseClassName}__header-logo-btn-icon`} subdomain={subdomain} />
          )}
          {!useCollapsedBehavior && (
            <NameLogoIcon className={`${baseClassName}__header-logo-btn-name-icon`} subdomain={subdomain} />
          )}
        </Link>
      </div>
      <ul className={`${baseClassName}__body`}>
        <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-tracking">
          <DashboardLeftSidebarNavBtn
            selected={pageId === 'tracking'}
            icon={<TrackingIcon />}
            onClick={() => {
              subAnalyticsOff();
            }}
            to={`${basePath}/tracking/orders${ordersQuery ? `?${queryString.stringify(ordersQuery)}` : ''}`}
            label="Tracking"
            testId="trackingSidebarButton"
          />
        </div>
        <div className={`${baseClassName}__tooltip`}>
          <Tooltip id="app-dashboard-left-sidebar-nav-tooltip-tracking" place="right" label="Tracking" />
        </div>
        {isAdmin && (
          <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-admin">
            <DashboardLeftSidebarNavBtn
              selected={pageId === 'admin'}
              icon={<AdminIcon />}
              onClick={() => {
                subAnalyticsOff();
              }}
              to={`${basePath}/admin`}
              label="Admin"
              testId="adminSidebarButton"
            />
            <div className={`${baseClassName}__tooltip`}>
              <Tooltip id="app-dashboard-left-sidebar-nav-tooltip-admin" place="right" label="Admin" />
            </div>
          </div>
        )}
        {shouldShowTools && (
          <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-tools">
            <DashboardLeftSidebarNavBtn
              selected={pageId === 'tools'}
              icon={<ToolsIcon />}
              to={`${basePath}/tools`}
              label="Tools"
              onClick={() => {
                subAnalyticsOff();
              }}
              testId="toolsSidebarButton"
            />
            <div className={`${baseClassName}__tooltip`}>
              <Tooltip id="app-dashboard-left-sidebar-nav-tooltip-tools" place="right" label="Tools" />
            </div>
          </div>
        )}
        {showAnalytics && firstAvailableAnalyticsPage && (
          <div
            onMouseEnter={useCollapsedBehavior ? subAnalyticsOn : undefined}
            onMouseLeave={useCollapsedBehavior ? subAnalyticsOff : undefined}
          >
            <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-analytics">
              <DashboardLeftSidebarNavBtn
                selected={showSubAnalytics || (useCollapsedBehavior && pageId === 'analytics')}
                icon={<AnalyticsIcon />}
                onClick={
                  isCollapsed
                    ? undefined
                    : () => {
                        toggleAnalytics();
                      }
                }
                label="Analytics"
                to={!showSubAnalytics ? `${basePath}/analytics/${firstAvailableAnalyticsPage}` : ''}
                dropDown
                testId="analyticsSidebarButton"
              />
            </div>
            {showSubAnalytics && (
              <div className={`${baseClassName}__dropdown`}>
                {showScorecard && (
                  <div className={`${baseClassName}__sub-button`}>
                    <DashboardLeftSidebarNavBtn
                      selected={currentAnalyticsPage === 'scorecard'}
                      icon={<ChartAreaIcon />}
                      to={`${basePath}/analytics/scorecard`}
                      label="Scorecard"
                      onClick={() => setCurrentAnalyticsPage('scorecard')}
                      subButton
                      testId="scorecardSidebarButton"
                    />
                  </div>
                )}
                {showDemurrage && (
                  <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-analytics-demurrage">
                    <DashboardLeftSidebarNavBtn
                      selected={currentAnalyticsPage === 'demurrage'}
                      icon={<ContainerStorageIcon />}
                      to={`${basePath}/analytics/demurrage`}
                      label="Demurrage"
                      onClick={() => setCurrentAnalyticsPage('demurrage')}
                      subButton
                      testId="demurrageSidebarButton"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {showShipperDashSettingsTab && (
          <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-settings">
            <DashboardLeftSidebarNavBtn
              selected={pageId === 'settings'}
              icon={<CogIcon />}
              to={`${basePath}/settings`}
              label="Settings"
              onClick={() => {
                subAnalyticsOff();
              }}
              testId="settingsSidebarButton"
            />
            <div className={`${baseClassName}__tooltip`}>
              <Tooltip id="app-dashboard-left-sidebar-nav-tooltip-settings" place="right" label="Settings" />
            </div>
          </div>
        )}
        {showShipperSelfService && (
          <div
            onMouseEnter={
              useCollapsedBehavior
                ? () => {
                    setShowSubSelfService(true);
                  }
                : undefined
            }
            onMouseLeave={
              useCollapsedBehavior
                ? () => {
                    setShowSubSelfService(false);
                  }
                : undefined
            }
          >
            <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-self-service">
              <DashboardLeftSidebarNavBtn
                selected={showSubSelfService || (useCollapsedBehavior && pageId === 'self-service')}
                icon={<ShipIcon />}
                to={!showSubSelfService ? `${basePath}/self-service/${currentSelfServicePage}` : ''}
                label="Self Service"
                testId="selfServiceSidebarButton"
                onClick={
                  isCollapsed
                    ? undefined
                    : () => {
                        toggleSelfService();
                      }
                }
                dropDown
              />
              <div className={`${baseClassName}__tooltip`}>
                <Tooltip id="app-dashboard-left-sidebar-nav-tooltip-self-service" place="right" label="Self Service" />
              </div>
            </div>
            {showSubSelfService && (
              <div className={`${baseClassName}__dropdown`}>
                <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-self-service-create-order">
                  <DashboardLeftSidebarNavBtn
                    selected={pageId === 'self-service' && currentSelfServicePage === 'create-order'}
                    icon={<ContainerIcon />}
                    onClick={() => {
                      setCurrentSelfServicePage('create-order');
                    }}
                    to={`${basePath}/self-service/create-order`}
                    label="Create Order"
                    testId="createDeliveryOrderSidebarButton"
                    subButton
                  />
                  <div className={`${baseClassName}__tooltip`}>
                    <Tooltip
                      id="app-dashboard-left-sidebar-nav-tooltip-self-service-create-order"
                      place="right"
                      label="Create Order"
                    />
                  </div>
                </div>
                <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-consignee-creation">
                  <DashboardLeftSidebarNavBtn
                    selected={pageId === 'self-service' && currentSelfServicePage === 'consignee-creation'}
                    icon={<WarehouseIcon />}
                    onClick={() => {
                      setCurrentSelfServicePage('consignee-creation');
                    }}
                    to={`${basePath}/self-service/consignee-creation`}
                    label="Consignee Creation"
                    testId="consigneeCreationSidebarButton"
                    subButton
                  />
                  <div className={`${baseClassName}__tooltip`}>
                    <Tooltip
                      id="app-dashboard-left-sidebar-nav-tooltip-consignee-creation"
                      place="right"
                      label="Consignee Creation"
                    />
                  </div>
                </div>
                <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-self-service-quote">
                  <DashboardLeftSidebarNavBtn
                    selected={pageId === 'self-service' && currentSelfServicePage === 'quote'}
                    icon={<ContainerIcon />}
                    onClick={() => {
                      setCurrentSelfServicePage('quote');
                    }}
                    to={`${basePath}/self-service/quote`}
                    label="Quote"
                    testId="createQuoteSidebarButton"
                    subButton
                  />
                  <div className={`${baseClassName}__tooltip`}>
                    <Tooltip
                      id="app-dashboard-left-sidebar-nav-tooltip-self-service-quote"
                      place="right"
                      label="Quote"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </ul>
      <div className={`${baseClassName}__footer`}>
        <div data-tooltip-id="app-dashboard-left-sidebar-nav-tooltip-logout">
          <DashboardLeftSidebarNavBtn
            icon={<LogoutIcon />}
            onClick={onLogout}
            label="Log Out"
            testId="logOutSidebarButton"
          />
          <div className={`${baseClassName}__tooltip`}>
            <Tooltip id="app-dashboard-left-sidebar-nav-tooltip-logout" place="right" label="Log Out" />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default DashboardLeftSidebar;
