/*
Style Overrides

clearIndicator
container
control
dropdownIndicator
group
groupHeading
indicatorsContainer
indicatorSeparator
input
loadingIndicator
loadingMessage
menu
menuList
menuPortal
multiValue
multiValueLabel
multiValueRemove
noOptionsMessage
option
placeholder
singleValue
valueContainer
*/

export default {
  control: (provided, state) => {
    const style = {
      ...provided,
      alignItems: 'center',
      cursor: 'pointer',
      height: 50,
      minWidth: 120,
      border: 0,
      borderRadius: 0,
      borderBottom: '1px solid var(--color-gray-3)',
      // This line disable the blue border
      boxShadow: 0,
      '&:hover': {
        border: 0,
        borderBottom: '1px solid var(--color-gray-3)'
      }
      // padding: '20px 0'
    };

    const result =
      state.selectProps.stylesOverlay && state.selectProps.stylesOverlay.control
        ? {
            ...style,
            ...state.selectProps.stylesOverlay.control
          }
        : style;

    return result;
  },
  input: (provided) => ({
    ...provided,
    marginLeft: 0
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    marginTop: 3,
    zIndex: 3,
    marginBottom: 0
  }),
  menuList: (provided) => ({
    ...provided,
    display: 'block',
    overflowY: 'scroll',
    padding: 0,
    '::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
      height: 7,
      '-webkit-overflow-scrolling': 'auto'
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0,0,0,.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--color-gray-2)' : 'white',
    color: 'var(--color-gray-1)',
    '&:hover': {
      background: 'var(--color-gray-2)'
    },
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '140%',
    letterSpacing: '0.01em',
    padding: '14px 18px'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--color-gray-6)',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '140%',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    '& > *': {
      marginBottom: 3,
      marginRight: '5px',
      '&:last-child': {
        marginRight: 0
      }
    }
  })
};
