import React from 'react';
import { stopImpersonateUser } from '/app/actions/auth';
import { useAppStateDispatcher, useAppState } from '/lib/react-app-state';
import Component from './component';
import { getDepartmentDisplayName } from '/app/utils/resources/department';

import './style.css';

function SystemHeaderConsumer() {
  const appStateDispatcher = useAppStateDispatcher();
  const auth = useAppState('auth');
  const isImpersonating = useAppState('auth:impersonating');
  const userDepartment = useAppState('userDepartment');
  const name = auth && auth.jwt && `${auth.jwt.firstName} ${auth.jwt.lastName}`;
  const departmentName = userDepartment && getDepartmentDisplayName(userDepartment);

  const handleStopImpersonate = () => {
    appStateDispatcher(stopImpersonateUser());
  };

  return isImpersonating ? (
    <Component name={name} departmentName={departmentName} onStopImpersonate={handleStopImpersonate} />
  ) : null;
}

export default SystemHeaderConsumer;
