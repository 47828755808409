import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardLeftSidebar from './components/DashboardLeftSidebar';
import DashboardHeader from './components/DashboardHeader';

import './style.css';

interface DashboardLayoutProps {
  analyticsInfo: Record<string, string>;
}

function DashboardLayout({ analyticsInfo }: DashboardLayoutProps) {
  const baseClassName = 'app-dashboard-layout';
  const className = baseClassName;

  return (
    <div className={className}>
      <DashboardLeftSidebar
        basePath="/dashboard"
        showAnalytics={analyticsInfo && Object.keys(analyticsInfo).length > 0}
        showDemurrage={!!analyticsInfo?.demurrageUrl}
        showScorecard={!!analyticsInfo?.scorecardUrl}
      />
      <div className={`${baseClassName}__body`}>
        <DashboardHeader />
        <div className={`${baseClassName}__body-content`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
