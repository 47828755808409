import React from 'react';
import Pill from '/app/components/shared/Pill';

import './style.css';

function SelectDropdownPill(props) {
  const className = 'r-b-select-dropdown-pill';

  const handleDelete = () => {
    props.removeProps.onClick();
  };

  return <Pill className={className} label={props.children} onDelete={handleDelete} />;
}

export default SelectDropdownPill;
