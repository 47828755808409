import React from 'react';
import { useAppState } from '/lib/react-app-state';
import DashboardHeader from './component';

function DashboardHeaderConsumer() {
  const headerBodyComponent = useAppState('ui:dashboard:header:body');
  return <DashboardHeader bodyComponent={headerBodyComponent} />;
}

export default React.memo(DashboardHeaderConsumer);
