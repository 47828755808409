import React, { useState } from 'react';

import './style.css';

interface CheckboxProps {
  id?: string | number;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  alwaysChecked?: boolean;
  labelAnchor?: string;
  label?: any;
  variant?: 'square' | 'circle';
  onToggle?: (checked: boolean, props: CheckboxProps, event: Event) => void;
}

export default function Checkbox(props: CheckboxProps) {
  // State.
  const [state, setState] = useState({
    checked: props.alwaysChecked || props.checked,
    prevPropsChecked: !!props.checked
  });

  if (!props.alwaysChecked && state.prevPropsChecked !== props.checked) {
    state.prevPropsChecked = props.checked!;
    state.checked = props.checked;
  }

  // Event handlers.
  const handleClick = (event) => {
    if (!props.alwaysChecked) {
      setState((prevState) => {
        const updatedState = {
          ...prevState,
          checked: !prevState.checked
        };

        if (props.onToggle) {
          props.onToggle(updatedState.checked, props, event);
        }

        return updatedState;
      });
    }
  };

  let className = 'r-b-checkbox';
  let classNameInner = 'r-b-checkbox__inner';
  let labelClassName = 'r-b-checkbox__label';

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.labelAnchor === 'left') {
    labelClassName += ` ${labelClassName}--left`;
    classNameInner += ' r-b-checkbox__inner--right';
  }

  if (state.checked) {
    classNameInner += ' r-b-checkbox__inner--checked';
  }

  if (props.variant) {
    classNameInner += ` r-b-checkbox__inner-type--${props.variant}`;
  }

  return (
    <div className={className} onClick={props.disabled ? undefined : handleClick}>
      <div className={classNameInner} />
      {props.label && <span className={labelClassName}>{props.label}</span>}
    </div>
  );
}
