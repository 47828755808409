import React, { useEffect, useState } from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';

import './style.css';

interface LabeledSwitchProps {
  className: string;
  label: string;
  checked: boolean;
  onCheck: ReactSwitchProps['onChange'];
  disabled?: boolean;
}

function LabeledSwitch(props: LabeledSwitchProps) {
  const baseClassName = 'labeled-switch';
  return (
    <div className={`${baseClassName}__wrapper`}>
      <Switch
        onColor="#276DE2"
        offColor="#B9BFC5"
        uncheckedIcon={false}
        checkedIcon={false}
        onChange={props.onCheck}
        width={40}
        height={20}
        checked={props.checked}
        disabled={props.disabled}
        id={`${baseClassName}__${props.className}-switch`}
        className={`${baseClassName}__switch`}
      />
      <div className={`${baseClassName}__label`}> {props.label} </div>
    </div>
  );
}

export default LabeledSwitch;
