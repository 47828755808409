import React, { useEffect, useState } from 'react';
import Message from '../../shared/Message';
import Tableau from '../../shared/Tableau';
import { getDepartment } from '/app/utils/auth';
import QuestionCircleIcon from '../../../../media/icons/fa/svg/solid/question-circle.svg';
import Tooltip from '../../shared/Tooltip/component';
import { logAnalyticsPageView } from '/app/utils/analytics';

import './style.css';

interface AnalyticsPageProps {
  info: any;
  analyticsType: string; // 'demurrage' or 'scorecard'
  title: string;
}

function AnalyticsPage(props: AnalyticsPageProps) {
  const baseClassName = 'analytics-page';
  const [infoKey, setInfoKey] = useState<string | null>(null);
  const { info, analyticsType, title } = props;
  const department = getDepartment();

  const informationUrls = {
    scorecard: 'https://app.getguru.com/card/cBGayMyi/Scorecard-Guide',
    demurrage: 'https://app.getguru.com/card/cp4aGGzi/Demurrage-Guide'
  };

  useEffect(() => {
    /**
     * Just take the first info key for now since it should only ever be the demurrage url.
     *   Will need a rework once we have multiple dashes we want to show on this page
     */
    const tableauInfo = `${analyticsType}Url`;
    setInfoKey(tableauInfo);

    logAnalyticsPageView(`analytics/${analyticsType}`);
    return () => {
      setInfoKey(null);
    };
  }, []);

  return (
    <div className={baseClassName}>
      {title && (
        <div className={`${baseClassName}__title-section-row`}>
          <h1 className={`${baseClassName}__title-section-text`}>{title}</h1>
          <QuestionCircleIcon
            data-tooltip-id={`${baseClassName}-${analyticsType}-link`}
            onClick={() => {
              window.location.href = informationUrls[analyticsType];
            }}
            className={`${baseClassName}__title-section-question-icon`}
          />
          <Tooltip
            className={`${baseClassName}__tooltip`}
            id={`${baseClassName}-${analyticsType}-link`}
            place="right"
            label={`Click here to learn more about the ${title} Analytics`}
          />
        </div>
      )}
      {info.notes && <Message message={info.notes} className={`${baseClassName}__notes-section`} />}
      {infoKey && <Tableau url={info[infoKey]} companyName={department.name} />}
    </div>
  );
}

export default AnalyticsPage;
