import React, { useEffect, useState } from 'react';
import normalizeDelimitedTextareaValue from '../../../utils/normalizeDelimitedTextareaValue';
import request from '../../../utils/request';
import { api } from '../../../utils/url';
import { BackHaulReadyResponse, BackHaulReadyResponseUpdated, BackhaulReadyNotMeetingCriteriaResponse } from './types';
import Button from '../../shared/Button';
import Modal from '../../shared/Modal/component';
import {
  logAnalyticsClick,
  logAnalyticsError,
  logAnalyticsPageView,
  logAnalyticsSuccess
} from '../../../utils/analytics';

import './style.css';

async function postBackhaulReady(containerNumbers: string[]): Promise<BackHaulReadyResponse> {
  const url = api('/legs/backhaul-ready');
  const opts = {
    method: 'POST',
    body: JSON.stringify({ data: { containerNumbers } })
  };

  const promise = await request(url, opts);
  return promise;
}

export interface ToolsPageProps {}

function ToolsPage() {
  const [textareaValue, setTextareaValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [updated, setUpdated] = useState<BackHaulReadyResponseUpdated[]>([]);

  const [notMeetingCriteria, setNotMeetingCriteria] = useState<Array<BackhaulReadyNotMeetingCriteriaResponse>>([]);

  const [isOpen, setIsOpen] = useState(false);

  const normalizedValue = normalizeDelimitedTextareaValue(textareaValue);
  const containerNumbers = normalizedValue ? normalizedValue.split(' ') : [];

  const containerNumbersLength = containerNumbers.length;
  const updatedLength = updated.length;
  const notMeetingCriteriaLength = notMeetingCriteria.length;
  const total = updatedLength + notMeetingCriteriaLength;
  const updatedHeading = `${updatedLength} of ${total} containers successfully marked empty`;
  const invalidHeading = `${notMeetingCriteriaLength} of ${total} containers were unable to be marked empty. Please contact your account manager for support.`;

  const isEmpty = containerNumbersLength === 0;

  const isDisabled = isLoading || isEmpty;

  useEffect(() => {
    logAnalyticsPageView('tools');
  }, []);

  return (
    <div className="tools-page">
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className="justify-center items-center"
      >
        <div className="p-10 bg-white rounded-lg">
          <div className="text-lg font-medium">Confirm empty containers</div>
          <div className="text-lg font-normal">
            Mark {containerNumbersLength} container(s) empty and ready for pickup.
          </div>
          <div className="h-6" />
          <div className="flex justify-end">
            <Button
              label="OK"
              className={`text-lg width-104 ${
                isLoading ? '' : 'bg-blue'
              } text-white h-10 focus:outline-black hover:outline-black font-normal`}
              async
              onClick={async () => {
                logAnalyticsClick('mark containers empty', 'tools/mark-empty-bulk-update');
                setIsLoading(true);

                try {
                  const response = await postBackhaulReady(containerNumbers);
                  const { data } = response;
                  const { updated = [], notMeetingCriteria = [] } = data;
                  setUpdated(updated);
                  setNotMeetingCriteria(notMeetingCriteria);
                  setIsLoading(false);

                  logAnalyticsSuccess('tools/mark-empty-bulk-update', {
                    containersUpdated: updated.map((u) => u.container),
                    containersUpdatedCount: updated.length,
                    containersNotUpdated: notMeetingCriteria,
                    containersNotUpdatedCount: notMeetingCriteria.length,
                    totalContainerCount: containerNumbersLength
                  });
                } catch (err) {
                  setIsLoading(false);

                  logAnalyticsError('tools/mark-empty-bulk-update', {
                    totalContainerCount: containerNumbersLength,
                    error: err instanceof Error ? err.message : 'Unknown Error'
                  });
                } finally {
                  // Clear the text area
                  setTextareaValue('');
                  setIsOpen(false);
                }
              }}
            />
            <div className="w-4" />
            <Button
              variant="text"
              label="Cancel"
              className="text-lg width-104 h-10 focus:outline-black hover:outline-black font-normal"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      </Modal>

      <div className="p-16">
        <div>
          <h1>Mark Empty Bulk Update</h1>
        </div>
        <div className="h-6" />
        <div>
          <textarea
            className="width-600 height-200 p-10 textarea-border"
            onChange={(e) => {
              const { value } = e.target;
              setTextareaValue(value);
            }}
            placeholder="Enter container numbers here separated by spaces, comma, or hard returns."
            value={textareaValue}
          />
        </div>
        <div className="h-6" />
        <div>
          <Button
            className="px-4"
            type="button"
            color="secondary"
            loading={isLoading}
            label="Update Order Status"
            disabled={isDisabled}
            onClick={() => {
              setIsOpen(true);
            }}
          />
        </div>
        {updated.length > 0 ? (
          <div>
            <div>
              <h2 className="font-medium">{updatedHeading}</h2>
            </div>
            <div>
              <table className="table-border">
                <thead className="bg-gray">
                  <tr>
                    <th className="padding-x-20 padding-y-15 font-medium">Container</th>
                  </tr>
                </thead>
                <tbody>
                  {updated.map((x) => {
                    const { container } = x;
                    return (
                      <tr key={container}>
                        <td className="padding-x-20 padding-y-15">{container}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        {notMeetingCriteria.length > 0 ? (
          <div>
            <div>
              <h2 className="font-medium">{invalidHeading}</h2>
            </div>
            <div>
              <table className="table-border">
                <thead className="bg-gray">
                  <tr>
                    <th className="padding-x-20 padding-y-15 font-medium">Container</th>
                    {typeof notMeetingCriteria[0] !== 'string' ? (
                      <th className="padding-x-20 padding-y-15 font-medium">Reason</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {notMeetingCriteria.map((reason, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={`${reason.containerNumber}-${index}`}>
                      <td className="padding-x-20 padding-y-15">{reason.containerNumber}</td>
                      <td className="padding-x-20 padding-y-15">{reason.reason}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ToolsPage;
