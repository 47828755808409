export function openGlobalModal(component?: any) {
  return (appStateEvent: any) => {
    appStateEvent.dispatch('OPEN_GLOBAL_MODAL', {
      path: 'ui:globalModal',
      data: (prevState: any) => ({
        ...prevState,
        component: component || null,
        open: true
      })
    });
  };
}

export function closeGlobalModal() {
  return (appStateEvent: any) => {
    appStateEvent.dispatch('CLOSE_GLOBAL_MODAL', {
      path: 'ui:globalModal',
      data: () => {
        const newState = {
          component: null,
          open: false
        };

        return newState;
      }
    });
  };
}

export function openDashboardHeader(component?: any) {
  return (appStateEvent: any) => {
    appStateEvent.dispatch('OPEN_DASHBOARD_HEADER', {
      path: 'ui:dashboard:header:body',
      data: component
    });
  };
}

export function closeDashboardHeader() {
  return (appStateEvent: any) => {
    appStateEvent.dispatch('OPEN_DASHBOARD_HEADER', {
      path: 'ui:dashboard:header:body',
      data: null
    });
  };
}
