import React from 'react';
import Switch from 'react-switch';
import SelectDropdown from '../../../../shared/SelectDropdown';
import EmailAddresses from '../EmailAddresses';
import TrashIcon from '/media/icons/fa/svg/regular/trash.svg';

import './style.css';
import { ContainerDigestConfig } from '../../../../../actions/shipper-preferences';

interface DropDownSelection {
  label: string;
  value: string | number;
}

const DIGEST_TIMES_DROPDOWN_OPTIONS: DropDownSelection[] = [
  { label: '00:00', value: 0 },
  { label: '01:00', value: 1 },
  { label: '02:00', value: 2 },
  { label: '03:00', value: 3 },
  { label: '04:00', value: 4 },
  { label: '05:00', value: 5 },
  { label: '06:00', value: 6 },
  { label: '07:00', value: 7 },
  { label: '08:00', value: 8 },
  { label: '09:00', value: 9 },
  { label: '10:00', value: 10 },
  { label: '11:00', value: 11 },
  { label: '12:00', value: 12 },
  { label: '13:00', value: 13 },
  { label: '14:00', value: 14 },
  { label: '15:00', value: 15 },
  { label: '16:00', value: 16 },
  { label: '17:00', value: 17 },
  { label: '18:00', value: 18 },
  { label: '19:00', value: 19 },
  { label: '20:00', value: 20 },
  { label: '21:00', value: 21 },
  { label: '22:00', value: 22 },
  { label: '23:00', value: 23 }
];

const DIGEST_FORMAT_DROPDOWN_OPTIONS: DropDownSelection[] = [
  { label: 'XLSX', value: 'xlsx' },
  { label: 'CSV', value: 'csv' }
];

interface Props {
  dailyDigestConfig: ContainerDigestConfig | null;
  onDigestChange: (key: keyof ContainerDigestConfig, index: number, value: any) => void;
  onDigestAdd: (key: keyof Pick<ContainerDigestConfig, 'recipients' | 'times'>) => void;
  onDigestRemove: (key: keyof Pick<ContainerDigestConfig, 'recipients' | 'times'>, index) => void;
}

const getActiveFormatValue = (
  dailyDigestConfig: ContainerDigestConfig | null
): DropDownSelection | undefined | null => {
  if (!dailyDigestConfig) {
    return null;
  }

  const keys = Object.keys(dailyDigestConfig.format);

  const foundKey = keys.find((key) => dailyDigestConfig.format[key]);

  return DIGEST_FORMAT_DROPDOWN_OPTIONS.find((format) => format.value === foundKey);
};

function DailyDigestSettings({ dailyDigestConfig, onDigestChange, onDigestAdd, onDigestRemove }: Props) {
  const domId = 'digestEnabled';
  const baseClassName = 'daily-digest-settings';

  const addNewButtonClass = [`${baseClassName}__add-new-button`];

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__section-first`}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={domId} className={`${baseClassName}__label-container`}>
          <span>Enabled</span>
          <Switch
            onColor="#276DE2"
            offColor="#B9BFC5"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={(checked) => {
              onDigestChange('enabled', 0, checked);
            }}
            width={40}
            height={20}
            checked={!!dailyDigestConfig?.enabled}
            id={domId}
          />
        </label>
      </div>
      <div className={`${baseClassName}__section`}>
        <EmailAddresses
          emails={dailyDigestConfig?.recipients}
          subTitle="Configure the email addresses you wish to receive the Daily Digest"
          handleEmailChange={(value: string, index: number) => {
            onDigestChange('recipients', index, value);
          }}
          handleEmailRemove={(index: number) => {
            onDigestRemove('recipients', index);
          }}
          handleEmailAdd={() => {
            onDigestAdd('recipients');
          }}
        />
        <div className={`${baseClassName}__section-dropdown`}>
          <SelectDropdown
            options={DIGEST_FORMAT_DROPDOWN_OPTIONS}
            placeholder="Format"
            value={getActiveFormatValue(dailyDigestConfig)}
            onChange={(value) => {
              onDigestChange('format', 0, value.value);
            }}
          />
        </div>
      </div>
      <div className={`${baseClassName}__spacer`} />
      <div className={`${baseClassName}__section ${baseClassName}__section-vertical`}>
        <div>
          <div className={`${baseClassName}__title`}>Time Slots</div>
          <div className={`${baseClassName}__sub-title-container`}>
            <div>Configure the times you wish to receive the Daily Digest</div>
            <div>Emails sent daily and all times PST/PDT</div>
          </div>
        </div>

        <div className={`${baseClassName}__digest-times-list-container`}>
          {dailyDigestConfig?.times.map((time, index) => {
            let showTrashIcon = index > 0;

            if (index === 0 && time !== null) {
              showTrashIcon = true;
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={`${baseClassName}__digest-times-row`} key={`digest-time-${index}`}>
                <div className={`${baseClassName}__digest-times-row-text`}>Receive Daily Digest at this Time</div>
                <div className={`${baseClassName}__digest-times-row-dropdown-container`}>
                  <SelectDropdown
                    options={DIGEST_TIMES_DROPDOWN_OPTIONS}
                    placeholder=""
                    value={DIGEST_TIMES_DROPDOWN_OPTIONS[time !== null ? time + 1 : 0]}
                    onChange={(value) => {
                      onDigestChange('times', index, value);
                    }}
                  />
                  {showTrashIcon && (
                    <TrashIcon
                      onClick={() => {
                        onDigestRemove('times', index);
                      }}
                      className={`${baseClassName}__icon`}
                    />
                  )}
                </div>
              </div>
            );
          })}
          <div
            className={addNewButtonClass.join(' ')}
            onClick={() => {
              onDigestAdd('times');
            }}
          >
            &#43; Add New
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyDigestSettings;
