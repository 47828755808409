import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { Link } from 'react-router-dom';
import ChevronDownIcon from '/media/icons/fa/svg/light/chevron-down.svg';
import ChevronUpIcon from '/media/icons/fa/svg/light/chevron-up.svg';

import './style.css';
import NewFeatureAlert from '/app/components/shared/NewFeatureAlert';

export interface DashboardLeftSidebarNavBtnProps {
  className?: string;
  dataset?: Record<string, any>;
  icon: any;
  label?: string;
  selected?: boolean;
  to?: string;
  dropDown?: boolean;
  onClick?: (dataset: Record<string, any>, event: Record<string, any>) => void;
  subButton?: boolean;
  testId: string;
}

function DashboardLeftSidebarNavBtn(props: DashboardLeftSidebarNavBtnProps) {
  const baseClassName = 'app-dashboard-left-sidebar-nav-btn';
  let className = baseClassName;
  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.selected) {
    className += ` ${baseClassName}--selected`;
  }

  if (props.subButton) {
    className += ` ${baseClassName}--sub-button`;
  }

  let showDropDown;
  if (props.dropDown) {
    if (props.selected) {
      showDropDown = <ChevronUpIcon className={`${className}-dropdown`} />;
    } else {
      showDropDown = <ChevronDownIcon className={`${className}-dropdown`} />;
    }
  }

  const iconCopy = React.cloneElement(props.icon, { className: `${className}-icon` });
  return props.to ? (
    <Link
      className={className}
      to={props.to}
      onClick={(event) => {
        if (props.onClick) {
          props.onClick(props.dataset!, event);
        }
      }}
      data-testid={props.testId}
    >
      <div className={`${className}-icon-box`}>{iconCopy}</div>
      <p className={`${className}-label`}>{props.label}</p>
      {showDropDown}
    </Link>
  ) : (
    <button
      className={className}
      onClick={(event) => {
        if (props.onClick) {
          props.onClick(props.dataset!, event);
        }
      }}
      data-testid={props.testId}
    >
      <div className={`${className}-icon-box`}>{iconCopy}</div>
      <p className={`${className}-label`}>{props.label}</p>
      {showDropDown}
    </button>
  );
}

export default DashboardLeftSidebarNavBtn;
