import request from '../utils/request';
import { api } from '../utils/url';

enum InvoiceNotificationFromHubTypes {
  NONE = 'none',
  EDI210 = 'edi210',
  EMAIL = 'email'
}

enum StatusNotificationTypeOptions {
  NONE = 'none',
  EDI214 = 'edi214'
}

export interface InvoiceNotificationRecipients {
  to: string;
  cc?: string[] | null;
}

export interface ContainerDigestConfig {
  enabled: boolean;
  format: Record<'xlsx' | 'csv', boolean>;
  recipients: string[];
  times: number[];
  zendeskTicketId?: number | null;
}

interface ShipperPreference {
  shipperUUID: string;
  invoiceNotificationFromHubType: InvoiceNotificationFromHubTypes;
  batchInvoiceEmails: boolean;
  sendGridInvoiceTemplateVersion: string;
  createdAt: Date;
  updatedAt: Date;
  invoiceNotificationRecipients: InvoiceNotificationRecipients | null;
  ediIsaId: string;
  accountManager: string | null;
  statusNotificationType: StatusNotificationTypeOptions;
  includePODInInvoiceEmail: boolean;
  emptyConfirmationEmailRecipients: InvoiceNotificationRecipients | null;
  containerDigestConfig: ContainerDigestConfig | null;
  allowAppointmentUpdates: boolean;
}

export const updateShipperPreference = async (shipperPreference: Partial<ShipperPreference>) => {
  if (shipperPreference.containerDigestConfig) {
    shipperPreference.containerDigestConfig = {
      ...shipperPreference.containerDigestConfig,
      recipients: shipperPreference.containerDigestConfig.recipients.filter((r) => r),
      times: shipperPreference.containerDigestConfig.times.filter((t) => t !== null && t !== undefined)
    };
  }

  const url = api('/shipper-preferences');
  const options = {
    method: 'PATCH',
    body: JSON.stringify({ data: shipperPreference })
  };

  const { data } = await request(url, options);
  return data;
};

export const updateShipperPreferenceEmails = async (shipperUUID: string, emailAddresses: string[]) => {
  const url = api(`/shipper-preferences/${shipperUUID}`);
  const options = {
    method: 'POST',
    body: JSON.stringify({ data: emailAddresses })
  };

  const { data } = await request(url, options);
  return data;
};

export const fetchShipperPreferences = async (): Promise<ShipperPreference> => {
  const url = api(`/shipper-preference`);

  const res = await request(url);
  return res;
};
