export default function createEnvHostFromHostname(
  hostname: string,
  toSubdomain?: string,
  toPort?: number,
  env?: string
) {
  let result;

  if (hostname === 'localhost') {
    result = 'http://localhost';
    if (toPort) {
      result = `${result}:${toPort}`;
    }
    return result;
  }

  const hostParts = hostname.split('.');
  const domainExtension = hostParts[hostParts.length - 1];
  const endsWithCom = domainExtension === 'com';
  const isProd = hostParts.length === 3;

  let protocol = 'http';
  if (endsWithCom) {
    protocol = 'https';
  }

  if (endsWithCom && !isProd) {
    // handle new test/stage domains below
    // eg. accounts.test.drayalliance.com, accounts.stage.drayalliance.com
    if (env) {
      hostParts[1] = env;
    }
    if (toSubdomain) {
      hostParts[0] = toSubdomain;
    }
  } else {
    // handle old test/stage and all prod domains below
    // eg. accounts.drayalliance.test, accounts.drayalliance.stage, accounts.drayalliance.com
    if (env) {
      hostParts[hostParts.length - 1] = env;
    }
    if (toSubdomain) {
      hostParts[0] = toSubdomain;
    }
  }
  return `${protocol}://${hostParts.join('.')}`;
}
