/* eslint-disable import/no-import-module-exports */
import '@drayalliance/com-web-styleguide-react/lib/styles/v1';
import '../utils/history-event-addons';
import 'react-tooltip/dist/react-tooltip.css';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Env, getEnvFromHostname } from '@drayalliance/utils';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { datadogInspector, initRum } from '../utils/datadog';
import { initAnalytics } from '../utils/analytics';
import App from '../components/layouts/AppLayout';

initAnalytics();

const render = async () => {
  const { env } = getEnvFromHostname(window.location.hostname);

  let ldClientSideID;
  switch (env) {
    case Env.Stage:
      // STAGE - CLIENT ID
      ldClientSideID = '5fd17091b702a50ae41b12f4';
      break;
    case Env.Production:
      // PROD - CLIENT ID
      ldClientSideID = '5ed7e502c5b3d80a3576a8d4';
      break;
    case Env.Test:
    default:
      // TEST - CLIENT ID
      ldClientSideID = '5ed7e502c5b3d80a3576a8d3';
      break;
  }

  initRum(env);

  const LDProvider = await asyncWithLDProvider({
    // TODO: Move these keys into env variables
    clientSideID: ldClientSideID,
    options: {
      inspectors: [datadogInspector()]
    }
  });

  const root = createRoot(document.getElementById('app')!);
  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  );
};

if (module.hot) {
  module.hot.accept();
}

render();
