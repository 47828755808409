import actionEmitter from './app-state-manager';

// Middleware dev tools
export function debugMiddleware(actionType: string) {
  // eslint-disable-next-line no-console
  console.log('Action: ', actionType);
}

function updateSubscriber(dispatchEventBody: any) {
  let { data } = dispatchEventBody;
  const { path } = dispatchEventBody;

  if (dispatchEventBody.data instanceof Function) {
    data = data(actionEmitter.getState(path));
  }

  actionEmitter.dispatch(path, data);
}

function dispatch(actionType: string, dispatchEventBody: any) {
  if (process.env.NODE_ENV === 'development') {
    debugMiddleware(actionType);
  }

  if (dispatchEventBody && dispatchEventBody.constructor === Object) {
    updateSubscriber(dispatchEventBody);
  } else if (Array.isArray(dispatchEventBody)) {
    throw new TypeError('Use .dispatchBatch instead');
  }
}

function dispatchBatch(actionType: string, dispatchEventBody: any[]) {
  if (process.env.NODE_ENV === 'development') {
    debugMiddleware(actionType);
  }

  if (dispatchEventBody) {
    dispatchEventBody.forEach(updateSubscriber);
  }
}

function dispatchAction(callback: any) {
  return callback({
    dispatch,
    dispatchBatch,
    getState: actionEmitter.getState
  });
}

export default function useAppStateDispatcher() {
  return dispatchAction;
}
