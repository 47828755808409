/**
 * This function converts a lower snake-cased string into a capitalized display string
 *
 * @export
 * @param {string} str
 * @return {*}  {string}
 */
export function toDisplayTitleCase(str: string): string {
  const array = str.toLowerCase().replace(/_/g, ' ').split(' ');
  for (let i = 0; i < array.length; i++) {
    array[i] = array[i].charAt(0).toUpperCase() + array[i].slice(1);
  }
  return array.join(' ');
}
