import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CreateOrderPage from './components/CreateOrder';
import ConsigneeCreationPage from './components/ConsigneeCreationPage';
import QuotePage from './components/Quote';

import './style.css';

function SelfServicePage() {
  const baseClassName = 'self-service-page';

  const params = useParams<{ subPage: string }>();
  const { showShipperSelfService } = useFlags();

  // eslint-disable-next-line no-undef
  let component: JSX.Element | null = null;
  switch (params.subPage) {
    case 'create-order':
      component = <CreateOrderPage />;
      break;
    case 'consignee-creation':
      component = <ConsigneeCreationPage />;
      break;
    case 'quote':
      component = <QuotePage />;
      break;
    default:
      break;
  }

  if (!showShipperSelfService) {
    return <Navigate to="/" replace />;
  }

  return <div className={`${baseClassName}__component-container`}>{component}</div>;
}

export default SelfServicePage;
