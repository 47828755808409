import React, { useState } from 'react';
import LogoIcon from '/media/icons/com/da-symbol.svg';
import { whitelabel } from '../../../utils/url';

export interface VanityLogoProps {
  subdomain?: string;
  className?: string;
}

function VanityLogo(props: VanityLogoProps) {
  const [error, setError] = useState(false);
  return error || !props.subdomain ? (
    <LogoIcon className={props.className} />
  ) : (
    <img alt={`${props.subdomain} logo`} src={`${whitelabel(props.subdomain)}`} onError={() => setError(true)} />
  );
}

export default VanityLogo;
