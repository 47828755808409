export default function getNestedObject(nestedObj, pathArr) {
  if (nestedObj && Array.isArray(pathArr)) {
    const pathLen = pathArr.length;
    let i = 0;

    for (; i < pathLen; ++i) {
      if (nestedObj && (typeof nestedObj === 'object' || typeof nestedObj === 'string')) {
        nestedObj = nestedObj[pathArr[i]];
      } else {
        nestedObj = undefined;
        break;
      }
    }

    return nestedObj;
  }
}
