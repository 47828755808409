export function getDepartmentDisplayName(department) {
  if (!department) {
    return null;
  }

  return department.attributes
    ? department.attributes.nickname || department.attributes.name
    : department.nickname || department.name;
}

export function getDepartmentDisplayBillingAddress(department): string {
  if (!department) {
    return '';
  }

  const shippingStreet1 = department.attributes ? department.attributes.shippingStreet1 : department.shippingStreet1;
  const shippingStreet2 = department.attributes ? department.attributes.shippingStreet2 : department.shippingStreet2;
  const shippingCity = department.attributes ? department.attributes.shippingCity : department.shippingCity;
  const shippingState = department.attributes ? department.attributes.shippingState : department.shippingState;
  const shippingZipCode = department.attributes ? department.attributes.shippingZipCode : department.shippingZipCode;

  const street = `${shippingStreet1}${shippingStreet2 ? `, ${shippingStreet2}` : ''}`;
  const address = `${street}${shippingCity ? `, ${shippingCity}` : ''}${shippingState ? `, ${shippingState}` : ''}${
    shippingZipCode ? `, ${shippingZipCode}` : ''
  }`;

  if (address === 'null') {
    return '';
  }
  return address;
}

export const getDepartmentDisplayLabelWithAddress = (department) =>
  `${getDepartmentDisplayName(department)}\n${getDepartmentDisplayBillingAddress(department)}`;

export function isDepartmentAdmin(department) {
  return department && (department.type === 'admin' || department.type === 'superadmin');
}
