import React from 'react';
import Button from '/app/components/shared/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';

import './style.css';

export interface DashboardHeaderProps {
  bodyComponent?: any;
}

function DashboardHeader(props: DashboardHeaderProps) {
  const baseClassName = 'app-dashboard-header';
  const className = baseClassName;

  const { showShipperSelfService } = useFlags();

  let to = 'https://www.drayalliance.com/pricing-request';
  if (showShipperSelfService) {
    to = '/dashboard/self-service/quote';
  }

  return (
    <div className={className}>
      <div className={`${baseClassName}__body`}>{props.bodyComponent}</div>
      <nav className={`${baseClassName}__right-nav`}>
        <Button
          className={`${baseClassName}__right-nav-btn`}
          color="secondary"
          label="Get a Quote"
          defaultEvents
          newTab={!showShipperSelfService}
          to={to}
          testId="dashboardHeaderGetAQuoteButton"
        />
      </nav>
    </div>
  );
}

export default DashboardHeader;
