import React from 'react';
import Checkbox from '/app/components/shared/Checkbox';
import Tooltip from '/app/components/shared/Tooltip';
import InfoCircleIcon from '../../../../../../../media/icons/fa/svg/regular/info-circle.svg';
import './style.css';
import { toDisplayTitleCase } from '/app/components/pages/ToolsPage/utils';

interface CheckboxForUpdatesProps {
  onToggle: (boolean) => void;
  checked: boolean;
  updateType: string;
  tooltip: string;
}

function CheckboxForUpdates(props: CheckboxForUpdatesProps) {
  const baseClassName = 'checkbox-for-updates';
  const checkboxLabel = `${toDisplayTitleCase(props.updateType)} Updates`;

  return (
    <div className={`${baseClassName}-row`}>
      <Checkbox
        variant="square"
        data-testid={`settings-updates-${props.updateType}-checkbox`}
        onToggle={props.onToggle}
        checked={props.checked}
      />
      <div>{checkboxLabel}</div>
      <InfoCircleIcon
        data-tooltip-id={`${props.updateType}-update-settings-tooltip`}
        className={`${baseClassName}__tooltip-icon app-tooltip-icon`}
      />
      <Tooltip
        place="right"
        id={`${props.updateType}-update-settings-tooltip`}
        className={`${baseClassName}-update-settings-tooltip`}
      >
        {props.tooltip}
      </Tooltip>
    </div>
  );
}

export default CheckboxForUpdates;
