import React, { useState } from 'react';
import DeleteIcon from '/media/icons/fa/svg/light/times.svg';
import LoadingSpinner from '/app/components/shared/LoadingSpinner';

import './style.css';

interface PillProps {
  isLoading?: boolean;
  className?: string;
  label?: string;
  prefix?: string;
  deleteIcon?: any;
  onClick?: (props: PillProps, event: Object) => void;
  onDelete?: (props: PillProps, event: Object) => void;
}

function Pill(props: PillProps) {
  const [staticState] = useState({
    prevIsLoadingProp: props.isLoading
  });
  const [state, setState] = useState({
    isLoading: props.isLoading
  });

  if (staticState.prevIsLoadingProp !== props.isLoading) {
    staticState.prevIsLoadingProp = props.isLoading;
    state.isLoading = props.isLoading;
  }

  const handleClick = (event) => {
    if (props.onClick) {
      props.onClick(props, event);
    }
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();

    setState((prevState) => ({
      ...prevState,
      isLoading: true
    }));

    if (props.onDelete) {
      props.onDelete(props, event);
    }
  };

  let className = 'r-b-pill';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className} onClick={handleClick || null}>
      {state.isLoading ? (
        <LoadingSpinner className="r-b-pill__loader" />
      ) : (
        props.prefix && <span className="r-b-pill__label-prefix">{props.prefix}</span>
      )}
      {props.label && <span className="r-b-pill__label">{props.label}</span>}
      {props.onDelete && (
        <div className="r-b-pill__icon-btn" onClick={handleDeleteClick}>
          {props.deleteIcon ? props.deleteIcon : <DeleteIcon />}
        </div>
      )}
    </div>
  );
}

export default Pill;
