import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import './style.css';
import DayWindowSwitch from './components/DayWindow/component';
import LabeledSwitch from './components/LabeledSwitch/component';
import { Days, ImportExport, ImportExportHours, ImportExportHoursInvalid, TimeType, orderedDays } from './consumer';
import Button from '/app/components/shared/Button/component';

interface ConsigneeCreationPageProps {
  onSaveClicked: () => void;
  toggle24_7Facility: () => void;
  toggleSameImportExport: () => void;
  toggleSameWeekdayHours: () => void;
  toggleApptNeeded: () => void;
  invalidWarehouseHours: ImportExportHoursInvalid;
  updateOpenCloseTime: (updatedTime: string, day: Days, importExport: ImportExport, timeType: TimeType) => void;
  onSOPTimeSwitch: (dayName: Days, importExport: ImportExport) => void;
  facility247: boolean;
  sameImportExport: boolean;
  sameWeekdayHours: boolean;
  apptNeeded: boolean;
  sopOperationalHoursImport: ImportExportHours;
  sopOperationalHoursExport: ImportExportHours;
  handleConsigneeNameChange: (name: string) => void;
  handleConsigneeNicknameChange: (nickname: string) => void;
  handleConsigneeAddressChange: (addressData: {
    place: google.maps.places.PlaceResult | null;
    inputVal: string;
  }) => void;
  saveButtonEnabled: boolean;
  consigneeName: string;
  consigneeNickname: string;
  consigneeAddress: { place: google.maps.places.PlaceResult | null; inputVal: string };
  clearForm: () => void;
}

function generateSOPTimeSwitches(
  onSOPTimeSwitch: (day: Days, importExport: ImportExport) => void,
  updateOpenCloseTime: (updatedValue: string, day: Days, importExport: ImportExport, timeType: TimeType) => void,
  importExport: ImportExport,
  importExportHours: ImportExportHours,
  importExportHoursInvalid: ImportExportHoursInvalid,
  sameWeekdayHours: boolean
) {
  if (sameWeekdayHours) {
    return (
      <div>
        <DayWindowSwitch
          importExport={importExport}
          day={Days.SUNDAY}
          enabled={importExportHours[Days.SUNDAY] !== undefined}
          key="day-window-switch-sunday"
          onCheck={onSOPTimeSwitch}
          onChange={updateOpenCloseTime}
          invalidDate={importExportHoursInvalid[importExport][Days.SUNDAY]?.invalid || false}
          openTimeValue={importExportHours[Days.SUNDAY]?.[TimeType.OPEN] ?? ''}
          closeTimeValue={importExportHours[Days.SUNDAY]?.[TimeType.CLOSE] ?? ''}
        />
        <DayWindowSwitch
          importExport={importExport}
          day={Days.WEEKDAYS}
          enabled={importExportHours[Days.MONDAY] !== undefined}
          key="day-window-switch-weekdays"
          onCheck={onSOPTimeSwitch}
          onChange={updateOpenCloseTime}
          invalidDate={importExportHoursInvalid[importExport][Days.MONDAY]?.invalid || false}
          openTimeValue={importExportHours[Days.MONDAY]?.[TimeType.OPEN] ?? ''}
          closeTimeValue={importExportHours[Days.MONDAY]?.[TimeType.CLOSE] ?? ''}
        />
        <DayWindowSwitch
          importExport={importExport}
          day={Days.SATURDAY}
          enabled={importExportHours[Days.SATURDAY] !== undefined}
          key="day-window-switch-saturday"
          onCheck={onSOPTimeSwitch}
          onChange={updateOpenCloseTime}
          invalidDate={importExportHoursInvalid[importExport][Days.SATURDAY]?.invalid || false}
          openTimeValue={importExportHours[Days.SATURDAY]?.[TimeType.OPEN] ?? ''}
          closeTimeValue={importExportHours[Days.SATURDAY]?.[TimeType.CLOSE] ?? ''}
        />
      </div>
    );
  }
  return (
    <div>
      {orderedDays().map((day) => (
        <DayWindowSwitch
          importExport={importExport}
          day={day}
          enabled={importExportHours[day] !== undefined}
          key={`day-window-switch-${day}`}
          onCheck={onSOPTimeSwitch}
          onChange={updateOpenCloseTime}
          invalidDate={importExportHoursInvalid[importExport][day]?.invalid || false}
          openTimeValue={importExportHours[day]?.[TimeType.OPEN] ?? ''}
          closeTimeValue={importExportHours[day]?.[TimeType.CLOSE] ?? ''}
        />
      ))}
    </div>
  );
}

function ConsigneeCreationPage(props: ConsigneeCreationPageProps) {
  const baseClassName = 'consignee-creation-page';

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      props.handleConsigneeNameChange(event.target.value);
    },
    [props.handleConsigneeNameChange]
  );

  const handleNicknameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      props.handleConsigneeNicknameChange(event.target.value);
    },
    [props.handleConsigneeNicknameChange]
  );

  const handleAddressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      props.handleConsigneeAddressChange({ place: props.consigneeAddress.place, inputVal: event.target.value });
    },
    [props.consigneeAddress, props.handleConsigneeAddressChange]
  );

  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);

  const options = {
    types: ['address'],
    componentRestrictions: { country: 'us' }
  };

  useEffect(() => {
    if (addressInputRef.current === null) return;
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(addressInputRef.current, options);
    autoCompleteRef.current?.addListener('place_changed', () => {
      const place = autoCompleteRef.current?.getPlace();
      if (place) {
        props.handleConsigneeAddressChange({ place, inputVal: place.formatted_address ?? '' });
      }
    });
  }, []);

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header`}>
        <div>Consignee Creation</div>
      </div>

      <div className={`${baseClassName}__main-content-container`}>
        <div className={`${baseClassName}__button-content-container`}>
          Enter Consignee Information
          <div className={`${baseClassName}__button-container`}>
            <Button
              className={`${baseClassName}__clear-button`}
              loading={false}
              type="reset"
              label="Clear"
              color="primary"
              onClick={props.clearForm}
              data-testid="clear-consignee-creation-form"
            />

            <Button
              className={`${baseClassName}__create-button`}
              loading={false}
              type="submit"
              label="Create"
              color="secondary"
              disabled={!props.saveButtonEnabled}
              onClick={props.onSaveClicked}
              data-testid="create-consignee"
            />
          </div>
        </div>

        <div className={`${baseClassName}__h-6`} />

        <div className={`${baseClassName}__title`}>
          Account Information
          <div className={`${baseClassName}__sub-title`}>Basic Consignee Information</div>
        </div>

        <div className={`${baseClassName}__input-container`}>
          <div className={`${baseClassName}__input-row`} key={`${baseClassName}-consignee-name`}>
            <input placeholder="Consignee Name" value={props.consigneeName} onChange={handleNameChange} />
          </div>
          <div className={`${baseClassName}__input-row`} key={`${baseClassName}-consignee-nickname`}>
            <input placeholder="Consignee Nickname" value={props.consigneeNickname} onChange={handleNicknameChange} />
          </div>
          <div className={`${baseClassName}__input-row`} key={`${baseClassName}-consignee-address`}>
            <input
              placeholder="Shipping Address"
              value={props.consigneeAddress.inputVal}
              onChange={handleAddressChange}
              ref={addressInputRef}
            />
          </div>
        </div>

        <div className={`${baseClassName}__spacer`} />
        <div className={`${baseClassName}__title`}>Operational Information</div>

        <div className={`${baseClassName}__sub-title`}>Preferred Operation Hours (Times are in PDT)</div>
        <div className={`${baseClassName}__labeled_switch_wrapper`}>
          <LabeledSwitch
            className="appt_needed"
            label="Appointment Needed"
            checked={props.apptNeeded}
            onCheck={props.toggleApptNeeded}
          />
        </div>

        <div className={`${baseClassName}__labeled_switch_wrapper_important`}>
          <LabeledSwitch
            className="24-7-switch"
            label="24/7 Facility"
            checked={props.facility247}
            onCheck={props.toggle24_7Facility}
          />
        </div>

        {!props.facility247 && (
          <div>
            <div className={`${baseClassName}__labeled_switch_wrapper`}>
              <LabeledSwitch
                className="same-import-export"
                label="Same Import/Export Hours"
                checked={props.sameImportExport}
                onCheck={props.toggleSameImportExport}
                disabled={props.facility247}
              />
            </div>

            <div className={`${baseClassName}__labeled_switch_wrapper_important`}>
              <LabeledSwitch
                className="same-weekday-hours"
                label="Same Weekday Hours"
                checked={props.sameWeekdayHours}
                onCheck={props.toggleSameWeekdayHours}
                disabled={props.facility247}
              />
            </div>

            <div className={`${baseClassName}__sop-time`}>
              <div className={`${baseClassName}__day-window-container`}>
                <div className={`${baseClassName}__label`}>
                  {' '}
                  {!props.sameImportExport ? 'Import Hours' : 'Import/Export Hours'}{' '}
                </div>
                {generateSOPTimeSwitches(
                  props.onSOPTimeSwitch,
                  props.updateOpenCloseTime,
                  ImportExport.IMPORT,
                  props.sopOperationalHoursImport,
                  props.invalidWarehouseHours,
                  props.sameWeekdayHours
                )}
              </div>
              {!props.sameImportExport && (
                <div className={`${baseClassName}__day-window-container`}>
                  <div className={`${baseClassName}__label`}> Export Hours </div>
                  {generateSOPTimeSwitches(
                    props.onSOPTimeSwitch,
                    props.updateOpenCloseTime,
                    ImportExport.EXPORT,
                    props.sopOperationalHoursExport,
                    props.invalidWarehouseHours,
                    props.sameWeekdayHours
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ConsigneeCreationPage;
