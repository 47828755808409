import React, { useState, useEffect, ReactNode } from 'react';
import LoaderSpinner from '../LoadingSpinner';

import './style.css';

export interface ButtonProps {
  id?: any;
  async?: boolean; // button will show spinner as soon as it is clicked
  className?: string;
  color?: 'primary' | 'secondary';
  defaultEvents?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  loading?: boolean; // controls when to hide the spinner and reenable the button
  label?: string;
  layoutDirection?: 'rtl';
  newTab?: boolean;
  onClick?: (event: Object, cProps?: ButtonProps) => void;
  to?: string;
  variant?: 'outlined' | 'text';
  type: 'submit' | 'button' | 'reset';
  floating?: boolean;
  shape?: 'circle';
  // TODO: Make this required
  testId?: string;
}

function Button(props: ButtonProps) {
  const [loading, setIsLoading] = useState(props.loading);

  const baseClassName = 'r-b-btn';
  let className = baseClassName;
  const disabled = props.disabled || loading;

  if (props.layoutDirection === 'rtl') {
    className += ` ${baseClassName}-layout--rtl`;
  }

  if (props.variant) {
    className += ` ${baseClassName}-type-${props.variant}`;

    if (props.disabled || loading) {
      className += ` ${baseClassName}-type-${props.variant}--disabled`;
    } else if (props.color) {
      className += ` ${baseClassName}-type-${props.variant}--${props.color}`;
    }
  } else if (props.disabled || loading) {
    className += ` ${baseClassName}--disabled`;
  } else if (props.color) {
    className += ` ${baseClassName}--${props.color}`;
  }

  if (props.floating) {
    className += ` ${baseClassName}--floating`;
  }

  if (props.shape) {
    className += ` ${baseClassName}--${props.shape}`;
  }

  if (props.className) {
    className += ` ${props.className}`;
  }
  const icon = loading ? <LoaderSpinner /> : props.icon;

  const handleClick = (event) => {
    if (props.async) {
      setIsLoading(true);

      if (props.onClick) {
        props.onClick(event, props);
      }
    } else if (props.onClick) {
      props.onClick(event, props);
    }
  };

  useEffect(() => {
    setIsLoading(props.loading);
  }, [props.loading]);

  const componentContent = (
    <>
      {icon}
      {props.label && icon && <div className={`${baseClassName}__icon-spacer`} />}
      {props.label}
    </>
  );

  if (props.to) {
    return (
      <a
        className={className}
        href={props.to}
        target={props.newTab ? '_blank' : undefined}
        onClick={(event) => {
          if (!props.defaultEvents) {
            event.preventDefault();
          }

          handleClick(event);
        }}
        data-testid={props.testId}
        rel="noreferrer"
      >
        {componentContent}
      </a>
    );
  }

  // adding floating to button causes error in console on render
  const { floating, testId, ...otherProps } = props;
  return (
    <button
      {...otherProps}
      type={props.type}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      data-testid={props.testId}
    >
      {componentContent}
    </button>
  );
}

Button.defaultProps = {
  type: 'button'
};

export default Button;
