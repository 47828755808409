import React from 'react';
import InfoCircleIcon from '/media/icons/fa/svg/regular/info-circle.svg';
import ExclamationCircleIcon from '/media/icons/fa/svg/regular/exclamation-circle.svg';
import ExclamationTriangleIcon from '/media/icons/fa/svg/regular/exclamation-triangle.svg';
import CheckCircleIcon from '/media/icons/fa/svg/regular/check-circle.svg';

import './style.css';

interface SystemToastProps {
  children?: any;
  type?: string;
  message?: string;
}

function SystemToast(props: SystemToastProps) {
  const className = 'app-system-toast';
  let iconClassName = 'app-system-toast__icon';
  let Icon: any = null;

  switch (props.type) {
    case 'info':
      Icon = InfoCircleIcon;
      iconClassName += ' app-system-toast__icon--info';
      break;
    case 'error':
      Icon = ExclamationCircleIcon;
      iconClassName += ' app-system-toast__icon--error';
      break;
    case 'warn':
      Icon = ExclamationTriangleIcon;
      iconClassName += ' app-system-toast__icon--warn';
      break;
    case 'success':
      Icon = CheckCircleIcon;
      iconClassName += ' app-system-toast__icon--success';
      break;
    default:
  }

  return (
    <article className={className}>
      {Icon && <Icon className={iconClassName} />}
      <p>{props.message}</p>
      {props.children && <div className="app-system-toast__actions">{props.children}</div>}
    </article>
  );
}

export default SystemToast;
