import React from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';

import './style.css';

interface DATooltipProps {
  id?: string;
  place?: PlacesType;
  delayShow?: number;
  children?: React.ReactNode;
  className?: string;
  label?: string;
}

function Tooltip(props: DATooltipProps) {
  let className = 'app-tooltip';
  const contentClassName = 'app-tooltip__content';
  const { label, children } = props;

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <ReactTooltip className={className} id={props.id} place={props.place} delayShow={props.delayShow}>
      <div className={contentClassName}>
        {' '}
        {children}
        {label && <span>{label}</span>}
      </div>
    </ReactTooltip>
  );
}

export default Tooltip;
