import React, { useCallback } from 'react';
// import React, { useCallback, useEffect } from 'react';
import Modal from './component';

import { useAppState, useAppStateDispatcher } from '/lib/react-app-state';
import { closeGlobalModal } from '/app/actions/ui';
// import { closeGlobalModal, openGlobalModal } from '/app/actions/ui';

function GlobalModalConsumer() {
  const appStateDispatcher = useAppStateDispatcher();
  const globalModalState = useAppState('ui:globalModal');

  const handleClose = useCallback(() => {
    appStateDispatcher(closeGlobalModal());
  }, []);

  // useEffect(() => {
  //   appStateDispatcher(openGlobalModal());
  // }, []);

  return globalModalState && globalModalState.open ? (
    <Modal open onClose={handleClose}>
      {globalModalState && globalModalState.component}
    </Modal>
  ) : null;

  // <Modal open={globalModalState && globalModalState.open} onClose={handleClose}>
}

export default GlobalModalConsumer;
