import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppState } from '../../../../lib/react-app-state';
import {
  ContainerDigestConfig,
  fetchShipperPreferences,
  InvoiceNotificationRecipients,
  updateShipperPreference
} from '../../../actions/shipper-preferences';
import UserDepartment from '../../../types/userDepartment';
import { logAnalyticsError, logAnalyticsPageView, logAnalyticsSuccess } from '../../../utils/analytics';
import { validateEmailAddresses } from '../../../utils/isValidEmail';
import SystemToast from '../../shared/SystemToast';
import { raiseToast } from '../../shared/Toaster';
import Component from './component';

const defaultDigestConfig = (): ContainerDigestConfig =>
  JSON.parse(
    JSON.stringify({
      times: [],
      format: { csv: false, xlsx: true },
      enabled: false,
      recipients: ['']
    })
  );

const parseDigestConfigRes = (config: ContainerDigestConfig) =>
  JSON.parse(
    JSON.stringify({
      ...config,
      times: config.times.length > 0 ? config.times : [null],
      recipients: config.recipients.length > 0 ? config.recipients : ['']
    })
  );

const parseBetterEmptiesConfig = (config: InvoiceNotificationRecipients) => [config.to ?? '', ...(config.cc ?? [])];

function SettingsPageConsumer() {
  const departmentState = useAppState<UserDepartment>('userDepartment');
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [betterEmptyEmailAddresses, setBetterEmptyEmailAddresses] = useState(['']);
  const [initialBetterEmptyEmailAddresses, setInitialBetterEmptyEmailAddresses] = useState(['']);

  const [dailyDigestConfig, setDailyDigestConfig] = useState<ContainerDigestConfig>(defaultDigestConfig());
  const [initialDailyDigestConfig, setInitialDailyDigestConfig] =
    useState<ContainerDigestConfig>(defaultDigestConfig());

  const [initialAppointmentUpdatePreferences, setInitialAppointmentUpdatePreferences] = useState(false);
  const [appointmentUpdatePreferences, setAppointmentUpdatePreferences] = useState(false);

  const handleCancel = useCallback(() => {
    setBetterEmptyEmailAddresses(initialBetterEmptyEmailAddresses);
    setDailyDigestConfig(() => JSON.parse(JSON.stringify(initialDailyDigestConfig)));
    setAppointmentUpdatePreferences(initialAppointmentUpdatePreferences);
    setSaveButtonEnabled(false);
  }, [
    initialBetterEmptyEmailAddresses,
    setBetterEmptyEmailAddresses,
    initialDailyDigestConfig,
    setDailyDigestConfig,
    setSaveButtonEnabled
  ]);

  const loadData = useCallback(async () => {
    try {
      const res = await fetchShipperPreferences();
      if (res.emptyConfirmationEmailRecipients) {
        setBetterEmptyEmailAddresses(parseBetterEmptiesConfig(res.emptyConfirmationEmailRecipients));
        setInitialBetterEmptyEmailAddresses(parseBetterEmptiesConfig(res.emptyConfirmationEmailRecipients));
      }
      if (res.containerDigestConfig) {
        setDailyDigestConfig(parseDigestConfigRes(res.containerDigestConfig));
        setInitialDailyDigestConfig(parseDigestConfigRes(res.containerDigestConfig));
      }
      if (res.allowAppointmentUpdates || res.allowAppointmentUpdates === false) {
        setAppointmentUpdatePreferences(res.allowAppointmentUpdates);
        setInitialAppointmentUpdatePreferences(res.allowAppointmentUpdates);
      }
      setIsLoading(false);
    } catch (err) {
      raiseToast(<SystemToast type="error" message="Error fetching shipper preferences" />, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      setIsLoading(false);
      if (err instanceof Error) {
        logAnalyticsError('Settings', {
          message: err.message,
          shipperUUID: departmentState.companyUuid,
          type: 'shipperPreferences'
        });
      } else {
        logAnalyticsError('Settings', {
          message: 'Unknown error while trying to fetch shipper preferences',
          shipperUUID: departmentState.companyUuid,
          type: 'shipperPreferences'
        });
      }
    }
  }, []);

  const handleSave = useCallback(async () => {
    const trimmedAddresses = betterEmptyEmailAddresses.map((s) => s?.trim()).filter((s) => s);

    const emailsAreValid = validateEmailAddresses(trimmedAddresses);
    setIsLoading(true);

    if (!emailsAreValid) {
      raiseToast(<SystemToast type="error" message="Emails are not valid" />, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      setIsLoading(false);
      return;
    }

    try {
      await updateShipperPreference({
        shipperUUID: departmentState.companyUuid,
        containerDigestConfig: dailyDigestConfig,
        emptyConfirmationEmailRecipients: {
          to: trimmedAddresses[0] ?? null,
          cc: trimmedAddresses.slice(1).length > 0 ? trimmedAddresses.slice(1).filter((a) => a) : null
        },
        allowAppointmentUpdates: appointmentUpdatePreferences
      });

      setIsLoading(false);
      raiseToast(<SystemToast type="success" message="Settings saved!" />, { position: toast.POSITION.BOTTOM_RIGHT });
      logAnalyticsSuccess('Settings', { type: 'notifications', shipperUUID: departmentState.companyUuid });
      setSaveButtonEnabled(false);
    } catch (err) {
      if (err instanceof Error) {
        raiseToast(<SystemToast type="error" message={err.message} />, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else {
        raiseToast(<SystemToast type="error" message="Error saving settings" />, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }

      setIsLoading(false);
      if (err instanceof Error) {
        logAnalyticsError('Settings', {
          message: err.message,
          shipperUUID: departmentState.companyUuid,
          type: 'notifications'
        });
      } else {
        logAnalyticsError('Settings', {
          message: 'Unknown error while trying to save',
          shipperUUID: departmentState.companyUuid,
          type: 'notifications'
        });
      }
    }

    await loadData();
  }, [betterEmptyEmailAddresses, dailyDigestConfig, appointmentUpdatePreferences]);

  const handleBetterEmptyEmailChange = useCallback((value: string, index: number) => {
    setBetterEmptyEmailAddresses((prevState) => {
      const newState = [...prevState];

      newState.splice(index, 1, value);

      return newState;
    });

    setSaveButtonEnabled(true);
  }, []);

  const handleBetterEmptyEmailAdd = useCallback(() => {
    setBetterEmptyEmailAddresses((prevState) => {
      const newState = [...prevState];

      newState.push('');

      return newState;
    });

    setSaveButtonEnabled(true);
  }, []);

  const handleBetterEmptyEmailRemove = useCallback((index: number) => {
    setBetterEmptyEmailAddresses((prevState) => {
      const newState = [...prevState];

      if (index === 0) {
        newState.splice(index, 1, '');
      } else {
        newState.splice(index, 1);
      }

      return newState;
    });

    setSaveButtonEnabled(true);
  }, []);

  const handleDigestChange = (key: keyof ContainerDigestConfig, index: number, value: any) => {
    setDailyDigestConfig((prevState) => {
      const newState = { ...prevState };

      if (key === 'times') {
        newState[key].splice(index, 1, value.value);
      } else if (key === 'recipients') {
        newState[key].splice(index, 1, value);
      } else if (key === 'format') {
        newState[key][value] = true;

        if (value === 'csv') {
          newState[key].xlsx = false;
        } else {
          newState[key].csv = false;
        }
      } else if (key === 'enabled') {
        newState[key] = value;
      }

      return newState;
    });
    setSaveButtonEnabled(true);
  };

  const handleAddDigest = (key: keyof Pick<ContainerDigestConfig, 'recipients' | 'times'>) => {
    setDailyDigestConfig((prevState) => {
      const newState = { ...prevState };

      if (key === 'times') {
        newState[key].push(undefined!);
      } else {
        newState[key].push('');
      }

      return newState;
    });
    setSaveButtonEnabled(true);
  };

  const handleRemoveDigest = (key: keyof Pick<ContainerDigestConfig, 'recipients' | 'times'>, index) => {
    setDailyDigestConfig((prevState) => {
      const newState = { ...prevState };

      if (index === 0) {
        if (key === 'recipients') {
          newState[key].splice(index, 1, '');
        } else {
          newState[key].splice(index, 1);
          newState[key].unshift(null!);
        }
      } else {
        newState[key].splice(index, 1);
      }

      return newState;
    });
    setSaveButtonEnabled(true);
  };

  const handleToggleAppointmentUpdate = (value) => {
    setAppointmentUpdatePreferences(value);
    if (initialAppointmentUpdatePreferences !== value) {
      setSaveButtonEnabled(true);
    } else {
      setSaveButtonEnabled(false);
    }
  };

  useEffect(() => {
    logAnalyticsPageView('Settings', { shipperUUID: departmentState.companyUuid });

    loadData();

    return () => {
      setIsLoading(false);
      setBetterEmptyEmailAddresses(['']);
      setDailyDigestConfig(null!);
      setAppointmentUpdatePreferences(false);
    };
  }, []);

  return (
    <Component
      saveButtonEnabled={saveButtonEnabled}
      isLoading={isLoading}
      onCancelClicked={handleCancel}
      onSaveClicked={handleSave}
      betterEmptyEmailAddresses={betterEmptyEmailAddresses}
      onBetterEmptyEmailChange={handleBetterEmptyEmailChange}
      onBetterEmptyEmailAdd={handleBetterEmptyEmailAdd}
      onBetterEmptyEmailRemove={handleBetterEmptyEmailRemove}
      dailyDigestConfig={dailyDigestConfig}
      onDigestChange={handleDigestChange}
      onDigestAdd={handleAddDigest}
      onDigestRemove={handleRemoveDigest}
      appointmentUpdates={appointmentUpdatePreferences}
      onToggleAppointmentUpdate={handleToggleAppointmentUpdate}
    />
  );
}

export default SettingsPageConsumer;
