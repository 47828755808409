import React from 'react';
import Modal from '/app/components/shared/Modal';
import TimesIcon from '../../../../media/icons/fa/svg/light/times.svg';

import './style.css';

export interface GlobalModalProps {
  children: any;
  open: boolean;
  onClose?: any;
}

function GlobalModal(props: GlobalModalProps) {
  const baseClassName = 'app-global-modal';
  const className = baseClassName;

  return (
    <Modal className={className} fullscreen open={props.open} onClose={props.onClose}>
      <TimesIcon className={`${baseClassName}__close-icon`} />
      {props.open && props.children}
    </Modal>
  );
}

export default React.memo(GlobalModal);
