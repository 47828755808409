import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { storeRouterQuery } from '/app/actions/router-query-cache';
import { useAppStateDispatcher, useAppState } from '/lib/react-app-state';

function AppStateRouterChache() {
  const appStateDispatcher = useAppStateDispatcher();
  const currentQuery = useAppState(`${window.location.pathname}:query`);
  const location = useLocation();

  const handleStoreQuery = () => {
    if (!currentQuery || `?${queryString.stringify(currentQuery)}` !== window.location.search) {
      appStateDispatcher(storeRouterQuery(queryString.parse(window.location.search)));
    }
  };

  useEffect(() => {
    handleStoreQuery();
  }, [location]);

  return null;
}

export default AppStateRouterChache;
