// Middleware dev tools
export function debugMiddleware(path: string, data) {
  // eslint-disable-next-line no-console
  console.log('Updated Path Details: ', {
    path,
    // @ts-ignore
    previousState: this.getState()[path],
    currentState: data
  });

  // Alphabetize state
  // @ts-ignore
  const state = this.getState();
  const sortedState = {};

  Object.keys(state)
    .sort()
    .forEach((key) => {
      sortedState[key] = state[key];
    });
}

class AppStateManager {
  actionSubscriberCache: { [key: string]: any } = {};

  state: { [key: string]: any } = {};

  updateCallback: any;

  initCallback: any;

  addActionListener = (statePath: string, callback: any) => {
    let subscriberGroup = this.actionSubscriberCache[statePath];

    if (subscriberGroup === undefined) {
      subscriberGroup = this.actionSubscriberCache[statePath] = [];
    }

    subscriberGroup.push(callback);
  };

  removeActionListener = (statePath: string, callback: any) => {
    const subscriberGroup = this.actionSubscriberCache[statePath];

    if (subscriberGroup !== undefined) {
      let subscriberGroupIndex = subscriberGroup.length;

      while (--subscriberGroupIndex >= 0) {
        if (subscriberGroup[subscriberGroupIndex] === callback) {
          subscriberGroup.splice(subscriberGroupIndex, 1);

          if (subscriberGroup.length === 0) {
            delete this.actionSubscriberCache[statePath];
          }

          break;
        }
      }
    }
  };

  dispatch = (statePath: string, data: any) => {
    this.triggerUpdate(statePath);
    const subscriberGroup = this.actionSubscriberCache[statePath];

    if (process.env.NODE_ENV === 'development') {
      debugMiddleware.call(this, statePath, data);
    }

    // Update state
    this.state[statePath] = data;

    // Call subscribers
    if (subscriberGroup !== undefined) {
      let subscriberGroupIndex = subscriberGroup.length;

      while (--subscriberGroupIndex >= 0) {
        subscriberGroup[subscriberGroupIndex](data);
      }
    }
  };

  resetState = () => {
    this.state = {};
  };

  getState = (key?: string) => (key ? this.state[key] : this.state);

  setState = (value: any) => {
    this.state = value;

    this.triggerInit();
    this.triggerUpdate();
  };

  onInit = (callback: (state: any) => void) => {
    this.initCallback = callback;
  };

  onUpdate = (callback: (state: any, statePath: string) => void) => {
    this.updateCallback = callback;
  };

  triggerInit = () => {
    if (typeof this.initCallback === 'function') {
      this.initCallback(this.state);
    }
  };

  triggerUpdate = (statePath?: string) => {
    if (typeof this.updateCallback === 'function') {
      this.updateCallback(this.state, statePath);
    }
  };
}

export default new AppStateManager();
