import React from 'react';
import request, { requestRules, isFetchCanceled } from '../utils/request';
import { sortOrder, sortOrders } from '../utils/resources/order';
import { encodeQueryStringValue } from '../utils/query-string-values';
import Toaster, { raiseToast, ToastPosition } from '../components/shared/Toaster';
import SystemToast from '../components/shared/SystemToast';
import { api } from '../utils/url';

const resourceType = 'orders';

export function retrieveOrderUuidsByOrderNumber(orderNumbers: string[]) {
  return async () => {
    try {
      const url = api('orders/order-numbers');
      const options = {
        query: { orderNumbers }
      };
      return await request(url, options);
    } catch (err) {
      throw err;
    }
  };
}

export function retrieveOrderCountsByFilter(filters: string[]) {
  return async () => {
    const query: { filters?: string } = {};

    if (Array.isArray(filters)) {
      query.filters = filters.join(',');
    }

    try {
      const url = api(`/order/filterCount`);
      const options = {
        details: {
          id: 'RETRIEVE_ORDER_FILTER_COUNTS',
          rule: requestRules.TAKE_LAST
        },
        query
      };

      return await request(url, options);
    } catch (err) {
      throw err;
    }
  };
}

export function retrieveOrderByIdBase(params, query) {
  return async () => {
    try {
      const url = api(`/${resourceType}/${params.id}`);
      const options = {
        headers: {
          Accept: 'application/vnd.da.raw+json'
        },
        query
      };
      const response = await request(url, options);
      const { data } = response;

      sortOrder(data);

      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function retrieveOrderById(id: string) {
  return retrieveOrderByIdBase(
    {
      id
    },
    {
      include: [
        'secondaryContainerStatusDepartment',
        'invoices.lineItems',
        'legs.stops.department',
        'legs.stops.attachments',
        'legs.stops.stopInstants',
        'legGroups.graypoolEmpties',
        'steamShippingLineAccount'
      ]
    }
  );
}

export function retrieveOrdersPaginatedBase(url, params?, query?) {
  return async (appStateEvent) => {
    try {
      const requestURL = url || api(`/${resourceType}`);
      const options = {
        details: {
          id: 'RETRIEVE_ORDERS_PAGINATED',
          rule: requestRules.TAKE_LAST
        },
        headers: {
          Accept: 'application/vnd.da.raw+json'
        },
        query: null
      };

      if (query) {
        options.query = query;
      }

      const response = await request(requestURL, options);

      sortOrders(response.data);

      appStateEvent.dispatch('RETRIEVE_ORDERS_PAGINATED_SUCCESS', {
        path: 'orders:paginated',
        data: response.data
      });

      return response;
    } catch (err) {
      appStateEvent.dispatch('RETRIEVE_ORDERS_PAGINATED_ERROR');

      if (!isFetchCanceled(err)) {
        raiseToast(
          <SystemToast type="error" message="There was an error loading orders. Please try refreshing the page." />,
          {
            // autoClose: false,
            position: ToastPosition.BOTTOM_RIGHT
          }
        );
      }

      throw err;
    }
  };
}

function generateOrdersQuery(queryParams: any) {
  const query: any = {
    include: [
      'secondaryContainerStatusDepartment',
      'legs.stops.department',
      'legs.stops.stopInstants',
      'legGroups.graypoolEmpties',
      'steamShippingLineAccount',
      'deliveryAccount',
      'pickupAccount',
      'returnAccount',
      'invoices.lineItems'
    ]
  };

  if (queryParams) {
    if (queryParams.fieldSort) {
      query.fieldSort =
        queryParams.fieldSort && typeof queryParams.fieldSort === 'object'
          ? encodeQueryStringValue(queryParams.fieldSort)
          : queryParams.fieldSort;
    }

    if (queryParams.filters) {
      query.filters =
        queryParams.filters && typeof queryParams.filters === 'object'
          ? encodeQueryStringValue(queryParams.filters)
          : queryParams.filters;
    }
  }

  return query;
}

export function retrieveOrdersPaginated(queryParams) {
  const query = generateOrdersQuery(queryParams);

  query['page[size]'] = queryParams.pageSize;

  return retrieveOrdersPaginatedBase(null, null, query);
}

export function retrieveOrdersByUrl(url) {
  return retrieveOrdersPaginatedBase(url);
}

export function retrieveOrdersCSV(filters) {
  return async (appStateEvent) => {
    try {
      const query = generateOrdersQuery({
        filters
      });
      const url = api(`/orders`);
      const options = {
        headers: {
          Accept: 'text/csv'
        },
        details: {
          responseType: 'text'
        },
        query
      };

      const response = await request(url, options);

      appStateEvent.dispatch('DOWNLOAD_ORDERS_CSV_SUCCESS');

      return response;
    } catch (err) {
      appStateEvent.dispatch('DOWNLOAD_ORDERS_CSV_ERROR');

      if (!isFetchCanceled(err)) {
        raiseToast(<SystemToast type="error" message={(err as Error).message} />, {
          position: ToastPosition.BOTTOM_RIGHT
        });
      }

      throw err;
    }
  };
}

export function retrieveOrdersXLSX(filters) {
  return async (appStateEvent) => {
    try {
      const query = generateOrdersQuery({
        filters
      });
      const url = api(`/orders`);
      const options = {
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        details: {
          responseType: 'arrayBuffer'
        },
        query
      };

      const response = await request(url, options);

      appStateEvent.dispatch('DOWNLOAD_ORDERS_XLSX_SUCCESS');

      return response;
    } catch (err) {
      appStateEvent.dispatch('DOWNLOAD_ORDERS_XLSX_ERROR');

      if (!isFetchCanceled(err)) {
        raiseToast(<SystemToast type="error" message={(err as Error).message} />, {
          position: ToastPosition.BOTTOM_RIGHT
        });
      }

      throw err;
    }
  };
}

export function retrieveOrderExport(filters, responseType, isImpersonating) {
  return async (appStateEvent) => {
    try {
      const query = generateOrdersQuery({
        filters
      });

      const url = api('orders/email');
      const options = {
        method: 'POST',
        headers: {
          Accept: responseType
        },
        query,
        body: JSON.stringify({ isImpersonating })
      };

      await request(url, options);
      appStateEvent.dispatch('SHIPPER_DASH_EXPORT_REQUEST_SUCCESS');
      raiseToast(<SystemToast type="success" message="Export email requested successfully." />, {
        position: ToastPosition.BOTTOM_RIGHT
      });
    } catch (err) {
      appStateEvent.dispatch('SHIPPER_DASH_EXPORT_REQUEST_FAILED');
      raiseToast(<SystemToast type="error" message="Export email request failed. Please try again." />, {
        position: ToastPosition.BOTTOM_RIGHT
      });
    }
  };
}

interface CreateOrderParams {
  containerNumber: string;
  billOfLading: string;
  importExport: string;
  steamShippingLineAccountUuid: string;
  pickupStopAccountUuid: string;
  deliveryStopAccountUuid: string;
  returnStopAccountUuid?: string;
  vesselName?: string | null;
  shipArrivalEta: string;
  size: string;
  deliveryType: string;
  quoteUuid?: string;
  // weight: number;
}

export function createOrder(order: CreateOrderParams) {
  return async (appStateEvent) => {
    try {
      const url = api(`/${resourceType}/create/sync`);
      const options = {
        method: 'POST',
        body: JSON.stringify(order)
      };

      const response = await request(url, options);

      appStateEvent.dispatch('CREATE_ORDER_SUCCESS', {
        path: 'orders:paginated',
        data: response?.data
      });

      return response;
    } catch (err) {
      appStateEvent.dispatch('CREATE_ORDER_ERROR');

      if (!isFetchCanceled(err)) {
        raiseToast(<SystemToast type="error" message="There was an error creating the order. Please try again." />, {
          position: ToastPosition.BOTTOM_RIGHT
        });
      }

      throw err;
    }
  };
}
