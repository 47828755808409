import React, { ChangeEvent } from 'react';
import ReactDatePicker from 'react-datepicker';
import DatePickerInput from '/app/components/shared/DatePicker/components/DatePickerInput';
import { DrayDateTime, drayDateTime } from '@drayalliance/utils';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

interface DateInputRowProps {
  label: string;
  value: DrayDateTime | null;
  onChange: (event: DrayDateTime) => void;
}

function DateInputRow({ label, value, onChange }: DateInputRowProps) {
  const baseClassName = 'date-input-row';

  const handleChange = (date: Date) => {
    onChange(drayDateTime(date));
  };

  return (
    <div className={baseClassName}>
      <div className="date-input-row__label">{label}</div>
      <ReactDatePicker
        selected={value?.toDate()}
        onChange={handleChange}
        dateFormat="EEE, MMM dd"
        customInput={<DatePickerInput single />}
      />
    </div>
  );
}

export default DateInputRow;
