import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DrayDateTime } from '@drayalliance/utils';
import InputRow from './components/InputRow';
import SelectRow from './components/SelectRow';
import Button from '../../../../../../shared/Button';
import { useAppState, useAppStateDispatcher } from '../../../../../../../../lib/react-app-state';
import { retrieveDepartmentsByType } from '../../../../../../../actions/departments';
import {
  getDepartmentDisplayLabelWithAddress,
  getDepartmentDisplayName
} from '../../../../../../../utils/resources/department';
import DateInputRow from './components/DateInputRow';
import { createOrder } from '/app/actions/orders';
import ErrorMessage from './components/ErrorMessage';

import './style.css';
import { Department } from '/app/components/pages/TrackingOrderDetailPage/components/TrackingOrderDetailEventCard/component';
import SystemToast from '/app/components/shared/SystemToast';
import { ToastPosition, raiseToast } from '/app/components/shared/Toaster';
import { isValidContainerNumber } from '/app/utils/isValidContainerNumber';
import { SelfServiceQuote, fetchQuotes } from '/app/actions/self-service-quotes';
import { filterAndSortQuotes } from '../../../Quote/component';

const CONTAINER_SIZES = {
  TWENTY_SD: '20 SD',
  TWENTY_SDR: '20 SDR',
  FOURTY_SD: '40 SD',
  FOURTY_SDR: '40 SDR',
  FOURTY_HQ: '40 HQ',
  FOURTY_HQR: '40 HQR',
  FOURTYFIVE_SD: '45 SD',
  FOURTYFIVE_SDR: '45 SDR',
  FOURTYFIVE_HQ: '45 HQ',
  FOURTYFIVE_HQR: '45 HQR'
};

export const CONTAINER_SIZE_DROPDOWN_OPTIONS = Object.values(CONTAINER_SIZES).map((size) => ({
  label: size,
  value: size.replace(/ /g, '')
}));

const DELIVERY_TYPE_DROPDOWN_OPTIONS = [
  { value: 'droppick', label: 'Drop & Pick' },
  { value: 'drop_only', label: 'Drop w/o Backhaul' },
  { value: 'live', label: 'Live' }
];

const isValidationError = (error: unknown): error is Error & { messages: string[] } => {
  if (error === undefined || error === null || typeof error !== 'object') {
    return false;
  }

  return 'messages' in error;
};

const isNormalError = (error: unknown): error is Error => {
  if (error === undefined || error === null || typeof error !== 'object') {
    return false;
  }

  return error instanceof Error;
};

interface InputRowState {
  value: string;
  error: string | null;
}

interface SelectRowState {
  value: { value: string; label: string } | null;
  error: string | null;
}

interface DateInputRowState {
  value: DrayDateTime | null;
  error: string | null;
}

function CreateOrderForm() {
  const baseClassName = 'create-order-form';

  const appStateDispatch = useAppStateDispatcher();
  const consigneeDepartments = useAppState<Department[] | undefined>('departments:consignee');
  const terminalDepartments = useAppState<Department[] | undefined>('departments:terminal');
  const shippingLineDepartments = useAppState<Department[] | undefined>('departments:shipping_line');

  const [quotes, setQuotes] = React.useState<SelfServiceQuote[]>([]);
  React.useEffect(() => {
    fetchQuotes().then((data) => {
      setQuotes(filterAndSortQuotes(data));
    });
  }, []);

  const [creatingOrder, setCreatingOrder] = useState(false);
  const [mainErrorMessages, setMainErrorMessages] = useState<string[]>([]);

  const [containerNumberState, setContainerNumberState] = useState<InputRowState>({
    value: '',
    error: null
  });

  const [bookingBLState, setBookingBLState] = useState<InputRowState>({
    value: '',
    error: null
  });

  const [importExportState, setImportExportState] = useState<SelectRowState>({
    value: { value: 'import', label: 'Import' },
    error: null
  });

  const [shippingLineState, setShippingLineState] = useState<SelectRowState>({
    value: null,
    error: null
  });

  const [pickupStopState, setPickupStopState] = useState<SelectRowState>({
    value: null,
    error: null
  });

  const [deliveryStopState, setDeliveryStopState] = useState<SelectRowState>({
    value: null,
    error: null
  });

  const [vesselNameState, setVesselNameState] = useState<InputRowState>({
    value: '',
    error: null
  });

  const [vesselETAState, setVesselETAState] = useState<DateInputRowState>({
    value: null,
    error: null
  });

  const [containerSizeState, setContainerSizeState] = useState<SelectRowState>({
    value: null,
    error: null
  });

  const [deliveryTypeState, setDeliveryTypeState] = useState<SelectRowState>({
    value: DELIVERY_TYPE_DROPDOWN_OPTIONS[0],
    error: null
  });

  const updateContainerNumber = (value: string) => {
    const isValidContainer = isValidContainerNumber(value);

    if (isValidContainer) {
      setContainerNumberState({ value, error: null });
    } else {
      setContainerNumberState({ value, error: 'Container Number is invalid' });
    }
  };

  const canCreateOrder = useMemo(
    () =>
      !!containerNumberState.value &&
      containerNumberState.error === null &&
      !!bookingBLState.value &&
      !!importExportState.value &&
      !!shippingLineState.value &&
      !!pickupStopState.value &&
      !!deliveryStopState.value &&
      !!vesselNameState.value &&
      !!vesselETAState.value &&
      !!containerSizeState.value &&
      !!deliveryTypeState.value,
    [
      containerNumberState,
      bookingBLState,
      importExportState,
      shippingLineState,
      pickupStopState,
      deliveryStopState,
      vesselNameState,
      vesselETAState,
      containerSizeState,
      deliveryTypeState
    ]
  );

  useEffect(() => {
    if (!consigneeDepartments) {
      appStateDispatch(retrieveDepartmentsByType('consignee'));
    }

    if (!terminalDepartments) {
      appStateDispatch(retrieveDepartmentsByType('terminal'));
    }

    if (!shippingLineDepartments) {
      appStateDispatch(retrieveDepartmentsByType('shipping_line'));
    }
  }, []);

  const terminalDepartmentDropdownOptions = useMemo(() => {
    if (!terminalDepartments) {
      return [];
    }

    return terminalDepartments.map((dep) => ({ value: dep.uuid, label: getDepartmentDisplayLabelWithAddress(dep) }));
  }, [terminalDepartments]);

  const consigneeDepartmentDropdownOptions = useMemo(() => {
    if (!consigneeDepartments) {
      return [];
    }

    return consigneeDepartments.map((dep) => ({ value: dep.uuid, label: getDepartmentDisplayLabelWithAddress(dep) }));
  }, [consigneeDepartments]);

  const shippingLineDepartmentDropdownOptions = useMemo(() => {
    if (!shippingLineDepartments) {
      return [];
    }

    return shippingLineDepartments.map((dep) => ({ value: dep.uuid, label: getDepartmentDisplayName(dep) }));
  }, [shippingLineDepartments]);

  const [quoteState, setQuoteState] = useState<SelectRowState>({
    value: null,
    error: null
  });

  const quoteDropdownOptions = useMemo(() => {
    const matchingQuotes = quotes.filter((quote) => quote.destinationDepartmentUuid === deliveryStopState.value?.value);
    const quoteDropdownOptions = matchingQuotes.map((quote) => ({
      value: quote.uuid,
      label: `Port LA/LB -> ${getDepartmentDisplayName(
        consigneeDepartments?.find((val) => val.uuid === quote.destinationDepartmentUuid)
      )} \nTotal Cost: $${quote.totalCost}`
    }));
    setQuoteState({ value: quoteDropdownOptions[0], error: null });
    const noQuoteOption = {
      value: 'none',
      label: deliveryStopState.value?.value === undefined ? 'Select a Delivery Stop' : 'None'
    };
    quoteDropdownOptions.push(noQuoteOption);
    if (quoteDropdownOptions.length === 1) {
      setQuoteState({ value: noQuoteOption, error: null });
    }
    return quoteDropdownOptions;
  }, [deliveryStopState, quotes, setQuotes]);

  const clearForm = () => {
    setMainErrorMessages([]);
    setContainerNumberState({ value: '', error: null });
    setBookingBLState({ value: '', error: null });
    setImportExportState({ value: { value: 'import', label: 'Import' }, error: null });
    setShippingLineState({ value: null, error: null });
    setPickupStopState({ value: null, error: null });
    setDeliveryStopState({ value: null, error: null });
    setVesselNameState({ value: '', error: null });
    setVesselETAState({ value: null, error: null });
    setContainerSizeState({ value: CONTAINER_SIZE_DROPDOWN_OPTIONS[0], error: null });
    setDeliveryTypeState({ value: DELIVERY_TYPE_DROPDOWN_OPTIONS[0], error: null });
  };

  const navigate = useNavigate();

  const handleCreateOrder = async () => {
    try {
      setMainErrorMessages([]);
      setCreatingOrder(true);
      const order = await appStateDispatch(
        createOrder({
          containerNumber: containerNumberState.value,
          billOfLading: bookingBLState.value,
          importExport: importExportState.value!.value,
          steamShippingLineAccountUuid: shippingLineState.value!.value,
          pickupStopAccountUuid: pickupStopState.value!.value,
          deliveryStopAccountUuid: deliveryStopState.value!.value,
          // returnStopAccountUuid: returnStopState.value?.value,
          vesselName: vesselNameState.value,
          shipArrivalEta: vesselETAState.value!.toISOString(),
          size: containerSizeState.value!.value,
          deliveryType: deliveryTypeState.value!.value,
          quoteUuid: quoteState.value?.value
        })
      );
      setCreatingOrder(false);
      clearForm();

      const orderId = order.uuid;
      navigate(`/dashboard/tracking/orders/${orderId}`);
    } catch (err) {
      const errorMessages: string[] = [];

      if (isValidationError(err)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const message of err.messages) {
          if (message.includes('containerNumber')) {
            errorMessages.push('Container Number is invalid');
            setContainerNumberState({ value: containerNumberState.value, error: 'Container Number is invalid' });
          }
        }
      }

      if (isNormalError(err)) {
        errorMessages.push(err.message);
      }

      setMainErrorMessages(errorMessages);
      setCreatingOrder(false);
    }
  };

  return (
    <div className={baseClassName}>
      {mainErrorMessages.length > 0 && <ErrorMessage messages={mainErrorMessages} />}
      <InputRow
        label="Container #"
        placeholder="Enter a Container Number"
        value={containerNumberState.value}
        error={containerNumberState.error}
        onChange={(value) => updateContainerNumber(value)}
      />
      <InputRow
        label="Booking / BL"
        placeholder="Enter a Bill of Lading"
        value={bookingBLState.value}
        onChange={(value) => setBookingBLState({ value, error: null })}
      />
      <SelectRow
        label="Import / Export"
        options={[
          { value: 'import', label: 'Import' },
          { value: 'export', label: 'Export' }
        ]}
        onChange={(value) => setImportExportState({ value, error: null })}
        value={importExportState.value}
      />
      <SelectRow
        label="Shipping Line"
        options={shippingLineDepartmentDropdownOptions}
        onChange={(value) => setShippingLineState({ value, error: null })}
        value={shippingLineState.value}
      />
      <SelectRow
        label="Pickup Stop"
        options={terminalDepartmentDropdownOptions}
        onChange={(value) => setPickupStopState({ value, error: null })}
        value={pickupStopState.value}
      />
      <SelectRow
        label="Delivery Stop"
        options={consigneeDepartmentDropdownOptions}
        onChange={(value) => setDeliveryStopState({ value, error: null })}
        value={deliveryStopState.value}
      />
      <div className={`${baseClassName}__newCnee`}>
        <Link to="/dashboard/self-service/consignee-creation">Create New Consignee</Link>
      </div>
      <InputRow
        label="Vessel Name"
        placeholder="Vessel Name"
        value={vesselNameState.value}
        onChange={(value) => setVesselNameState({ value, error: null })}
      />
      <DateInputRow
        label="Vessel ETA"
        value={vesselETAState.value}
        onChange={(value) => setVesselETAState({ value, error: null })}
      />
      <SelectRow
        label="Size"
        options={CONTAINER_SIZE_DROPDOWN_OPTIONS}
        onChange={(value) => setContainerSizeState({ value, error: null })}
        value={containerSizeState.value}
      />
      <SelectRow
        label="Delivery Type"
        options={DELIVERY_TYPE_DROPDOWN_OPTIONS}
        onChange={(value) => setDeliveryTypeState({ value, error: null })}
        value={deliveryTypeState.value}
      />
      <SelectRow
        label="Quote"
        options={quoteDropdownOptions}
        onChange={(value) => setQuoteState({ value, error: null })}
        disabled={quoteDropdownOptions.length === 1}
        value={quoteState.value}
      />
      <div className={`${baseClassName}__button-container`}>
        <Button
          className={`${baseClassName}__button`}
          label="Create Order"
          testId="CreateOrderButton"
          color="secondary"
          loading={creatingOrder}
          disabled={!canCreateOrder}
          onClick={handleCreateOrder}
        />
        <Button className={`${baseClassName}__button`} label="Clear" onClick={clearForm} color="secondary" />
      </div>
    </div>
  );
}

export default CreateOrderForm;
