import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { retrieveOrderUuidsByOrderNumber } from '/app/actions/orders';
import { logAnalyticsPageView } from '/app/utils/analytics';
import { useAppStateDispatcher } from '/lib/react-app-state';

function LinkOnlyToOrderDetail() {
  const { orderNum } = useParams<{ orderNum: string }>();

  const [state, setState] = useState({
    orderUuid: '',
    ready: false
  });
  const appStateDispatcher = useAppStateDispatcher();

  const retrieveOrderUuid = async () => {
    try {
      const result = await appStateDispatcher(retrieveOrderUuidsByOrderNumber([orderNum!]));
      const orderUuid = result.orderUuids[0];
      setState((prevState) => ({
        ...prevState,
        ready: true,
        orderUuid
      }));
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    logAnalyticsPageView(`orders/redirected-from-invoice`, { orderNumber: orderNum });
  }, []);

  retrieveOrderUuid();

  if (!state.ready) {
    return null;
  }

  return <Navigate to={`/dashboard/tracking/orders${state.orderUuid ? `/${state.orderUuid}` : ''}`} />;
}

export default React.memo(LinkOnlyToOrderDetail);
