import React, { useCallback } from 'react';
import { isDepartmentAdmin } from '/app/utils/resources/department';

import Component from './component';

import { useAppStateDispatcher, useAppState } from '/lib/react-app-state';
import { logout } from '/app/actions/auth';

interface DashboardLeftSidebarConsumerProps {
  basePath: string;
  showAnalytics: boolean;
  showDemurrage: boolean;
  showScorecard: boolean;
}

function DashboardLeftSidebarConsumer(props: DashboardLeftSidebarConsumerProps) {
  const appStateDispatcher = useAppStateDispatcher();
  const ordersQuery = useAppState('/dashboard/tracking/orders:query');
  const userDepartment = useAppState('userDepartment');
  const subdomain = userDepartment && userDepartment.redirectSubDomains && userDepartment.redirectSubDomains.shipper;

  const handleLogout = useCallback(() => {
    appStateDispatcher(logout());
  }, []);

  return (
    <Component
      onLogout={handleLogout}
      basePath={props.basePath}
      isAdmin={isDepartmentAdmin(userDepartment)}
      ordersQuery={ordersQuery}
      subdomain={subdomain}
      showAnalytics={props.showAnalytics}
      showDemurrage={props.showDemurrage}
      showScorecard={props.showScorecard}
    />
  );
}

export default React.memo(DashboardLeftSidebarConsumer);
