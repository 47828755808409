import React, { useRef, useEffect } from 'react';

import './style.css';

interface TableauInterface {
  url: string;
  companyName?: string;
}

function Tableau(props: TableauInterface) {
  const containerRef = useRef(null);
  const { url, companyName } = props;
  /* eslint-disable */
  const viz = useRef<Window['tableau'] | null>(null);
  /* eslint-enable */

  const initTableau = () => {
    if (!window.tableau) {
      return;
    }

    const options: any = {
      device: 'desktop',
      hideTabs: false,
      hideToolbar: true
    };

    if (companyName && companyName !== 'Dray Alliance Admin') {
      options.shippername = companyName;
    }

    viz.current = new window.tableau.Viz(containerRef.current, url, options);
  };

  useEffect(() => {
    initTableau();

    /** Cleanup */
    return () => {
      if (viz.current) {
        viz.current.dispose();
        viz.current = null;
      }
    };
  }, []);

  return <div className="tableau" ref={containerRef} />;
}

export default Tableau;
