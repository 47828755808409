import React from 'react';
import CheckboxForUpdates from './UpdateCheckbox/component';

interface UpdatesProps {
  onToggleAppointmentUpdate: (boolean) => void;
  appointmentUpdates: boolean;
}

function Updates(props: UpdatesProps) {
  const baseClassName = 'updates';

  const containerTooltip = (
    <div>
      <b>Appointment changed</b>
      <p>Notification sent for any changes to terminal or required warehouse appointments</p>
      <b>Appointment cancelled</b>
      <p>Notification sent for any cancellation of terminal or required warehouse appointments</p>
      <b>Appointment rescheduled</b>
      <p>
        Notification sent for rescheduled appointments if the original terminal or required warehouse appointment was
        cancelled
      </p>
    </div>
  );

  const updateTypesToTooltipMap = {
    container: {
      tooltip: containerTooltip,
      onToggle: props.onToggleAppointmentUpdate,
      checked: props.appointmentUpdates
    }
    // 'accessorial': '',
    // 'custom': '',
  };

  return (
    <div className={baseClassName} data-testid="settings-tab-updates">
      <div className="email-addresses__title">
        Select your notification updates
        <div className="email-addresses__sub-title">
          Opt into the updates you would like to receive via email by selecting the checkbox.
        </div>
      </div>
      {Object.keys(updateTypesToTooltipMap).map((updateType, i) => (
        <CheckboxForUpdates
          key={`update-checkboxes-${updateType}-key`}
          updateType={updateType}
          checked={updateTypesToTooltipMap[updateType].checked}
          tooltip={updateTypesToTooltipMap[updateType].tooltip}
          onToggle={updateTypesToTooltipMap[updateType].onToggle}
        />
      ))}
    </div>
  );
}

export default Updates;
