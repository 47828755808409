import React, { forwardRef } from 'react';
import CalendarIcon from '/media/icons/fa/svg/regular/calendar-alt.svg';

import './style.css';

interface InputProps {
  value: string;
  onClick: () => void;
  className: string;
  single?: boolean;
  [key: string]: any;
}

const DatePickerInput = forwardRef(({ value, onClick, single }: InputProps, ref: React.ForwardedRef<any>) => {
  const baseClassName = 'date-picker-input';
  const isEmpty = !value;

  let startValue = 'Select Date';
  let endValue = 'Select Date';
  if (!isEmpty) {
    const [start, end] = value.split('-');
    startValue = start?.trim();
    endValue = end?.trim();
  }

  return (
    <div className={baseClassName} onClick={onClick} ref={ref}>
      <CalendarIcon className={`${baseClassName}--icon`} />
      <span
        className={
          startValue === 'Select Date'
            ? `${baseClassName}--input ${baseClassName}--not-selected`
            : `${baseClassName}--input`
        }
      >
        {startValue}
      </span>
      {!single && (
        <>
          <div className={`${baseClassName}--divider`} />
          <span
            className={
              startValue === 'Select Date'
                ? `${baseClassName}--input ${baseClassName}--not-selected`
                : `${baseClassName}--input`
            }
          >
            {endValue}
          </span>
        </>
      )}
    </div>
  );
});

export default DatePickerInput;
