import { JwtPayload, jwtDecode } from 'jwt-decode';
import cookies from 'js-cookie';
import getNestedObject from './getNestedObject';

export const HUB_TOKEN_KEY = 'hub-token';
export const IMPERSONATE_TOKEN_KEY = 'impersonate-token';
export const REFRESH_TOKEN_KEY = 'refresh-token';
export const DOMAIN_NAME = 'drayalliance';

export function isValidDashobardUser(departmentType) {
  return departmentType === 'admin' || departmentType === 'customer';
}

export function isJWTValid(jwt) {
  if (jwt) {
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime < jwt.exp) {
      return true;
    }
  }

  return false;
}

export function getJWT(authToken) {
  if (authToken) {
    const jwt = jwtDecode<
      JwtPayload & { uuid: string; firstName: string; lastName: string; email1: string; departments: any[] }
    >(authToken);
    return jwt;
  }

  return null;
}

export function getRawHUBServiceAuthToken() {
  return cookies.get()[HUB_TOKEN_KEY] || null;
}

export function getRawHUBServiceImpersonateAuthToken() {
  return cookies.get()[IMPERSONATE_TOKEN_KEY] || null;
}

export function getHUBServiceAuthToken() {
  try {
    let rawToken = getRawHUBServiceImpersonateAuthToken();

    if (!rawToken) {
      rawToken = getRawHUBServiceAuthToken();

      if (rawToken) {
        const decodedAuthToken = atob(rawToken);
        const authToken = decodedAuthToken.replace(/['"]+/g, '');

        return authToken;
      }
    }

    return rawToken;
  } catch (e) {
    return null;
  }
}

export function decodeToken(token) {
  if (token) {
    try {
      let decodedAuthToken = atob(token);

      decodedAuthToken = decodedAuthToken.replace(/['"]+/g, '');

      return decodedAuthToken;
    } catch (e) {
      return null;
    }
  }

  return null;
}

export function setHUBToken(token) {
  cookies.set(HUB_TOKEN_KEY, token);
}

export function setImpersonateToken(token) {
  cookies.set(IMPERSONATE_TOKEN_KEY, token);
}

export function deleteTokens(tokenIds: string[]) {
  tokenIds.forEach((id) => {
    cookies.remove(id);
    cookies.remove(id, { path: '/', domain: `.${DOMAIN_NAME}.com` });
    cookies.remove(id, { path: '/', domain: `.${DOMAIN_NAME}.test` });
    cookies.remove(id, { path: '/', domain: `.${DOMAIN_NAME}.stage` });
    cookies.remove(id, { path: '/', domain: `test.${DOMAIN_NAME}.com` });
    cookies.remove(id, { path: '/', domain: `stage.${DOMAIN_NAME}.com` });
  });
}

export function deleteImpersonateToken() {
  deleteTokens([IMPERSONATE_TOKEN_KEY]);
}

export function deleteHUBToken() {
  deleteTokens([HUB_TOKEN_KEY]);
}

export function deleteRefreshToken() {
  deleteTokens([REFRESH_TOKEN_KEY]);
}

export const getDepartment = (jwt?) => {
  jwt = jwt || getJWT(getHUBServiceAuthToken());

  if (jwt) {
    try {
      const department = getNestedObject(jwt, ['departments', 0]);

      return department;
    } catch (err) {
      throw err;
    }
  }

  return null;
};

export const clearAuthTokens = () => {
  deleteTokens([IMPERSONATE_TOKEN_KEY, HUB_TOKEN_KEY, REFRESH_TOKEN_KEY]);
};

export const clearCookies = () => {
  Object.keys(cookies.get()).forEach((cookieName) => {
    cookies.remove(cookieName);
  });
};
