import queryString from 'query-string';
import { getDepartment, getHUBServiceAuthToken } from './auth';

export const requestRules = {
  TAKE_LAST: 'TAKE_LAST'
};

let requestConfig: Record<string, any> = {};

const requestCache: Record<string, any> = {};

export function cancelRequestById(id) {
  const requestDetails = requestCache[id];

  if (requestDetails) {
    if (requestDetails.controller) {
      requestDetails.controller.abort();
    }
  }
}

export function isFetchCanceled(err) {
  if (err.code === 20) {
    return true;
  }

  return false;
}

async function parseJSON(response: Response): Promise<any | null> {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.json();
}

async function parseBlob(response: Response): Promise<Blob | null> {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.blob();
}

async function parseArrayBuffer(response: Response): Promise<ArrayBuffer | null> {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.arrayBuffer();
}

async function parseText(response: Response): Promise<string | null> {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {Response} response   A response from a network request
 *
 * @return {Response|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  // if (response.status === 403) {
  //   history.push(`${BASE_URL}/login`);
  // }

  const resposneObj = await response.json();

  const error = new Error(resposneObj.message);

  // @ts-ignore
  error.errors = resposneObj.errors;

  // Validation errors from nestjs have message as an array
  if (Array.isArray(resposneObj.message)) {
    // @ts-ignore
    error.messages = resposneObj.message;
  }

  throw error;
}

function parseURL(url) {
  const parser = document.createElement('a');
  const searchObject = {};
  let queries;
  let split;
  let i;
  // Let the browser do the work
  parser.href = url;
  // Convert query string to object
  queries = parser.search.replace(/^\?/, '').split('&');
  for (i = 0; i < queries.length; i++) {
    split = queries[i].split('=');
    searchObject[split[0]] = split[1];
  }
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject,
    hash: parser.hash
  };
}

export default async function request<T = any>(url, opts?): Promise<T | undefined> {
  if (!url) {
    return;
  }

  let isDomesticDomain = true;
  let parser = parseJSON;
  let fetchPromise: Promise<Response> | null = null;
  let requestDetails: Record<string, any> | null = null;
  let query: string | null = null;
  const body = opts && opts.body;

  const defaultOptions = {
    method: 'GET',
    headers: {
      'Content-Type': body instanceof FormData ? 'multipart/form-data' : 'application/json',
      'da-source': 'web.shipper-dashboard'
    }
  };

  let options: Record<string, any> = defaultOptions;

  // start middleware.

  // Middleware
  if (requestConfig.use) {
    requestConfig.use(url, options);
  }

  // Send auth information only for domestic/same domain apis.
  // if (url[0] !== '/') {
  //   isDomesticDomain = false;
  // }
  const parsedUrl = parseURL(url);
  if (url[0] === '/' && url[1] !== '/' && window.location.hostname !== 'localhost') {
    const hostnameChunks = window.location.hostname.split('.');

    if (hostnameChunks.length > 2) {
      // remove subdomain. ex. shipper.drayalliance.com
      hostnameChunks.shift();
      url = `${window.location.protocol}//www.${hostnameChunks.join('.')}${url}`;
    }
  } else if (
    url[0] !== '/' &&
    !['localhost', 'drayalliance.test', 'drayalliance.stage', 'drayalliance.com'].some(
      (host) => parsedUrl.hostname.indexOf(host) === parsedUrl.hostname.length - host.length
    )
  ) {
    isDomesticDomain = false;
  }

  if (isDomesticDomain) {
    const authToken = getHUBServiceAuthToken();
    options.credentials = 'include';
    options.headers.Authorization = `Bearer ${authToken}`;
  } else {
    options.credentials = 'omit';
  }

  if (
    !/api.*\/hub\/login$/.test(url) &&
    !/api.*\/hub\/user\/password-reset\/.*$/.test(url) &&
    !/api.*\/hub\/user$/.test(url)
  ) {
    const department = getDepartment();

    if (department) {
      options.headers['da-department'] = department.uuid;
    } else {
      throw new Error('Department does not exist');
    }
  }

  // end middleware

  if (opts) {
    options = {
      ...defaultOptions,
      ...opts
    };
    options.headers = defaultOptions.headers;

    if (opts.headers) {
      options.headers = {
        ...options.headers,
        ...opts.headers
      };
    }

    if (opts.query && typeof opts.query === 'object') {
      query = queryString.stringify(opts.query, {
        arrayFormat: 'comma',
        encode: false
      });
    }

    if (typeof query === 'string') {
      if (query[0] !== '?') {
        query = `?${query}`;
      } else {
        query = `${query}`;
      }
    }

    if (query) {
      url += query;
    }

    if (opts.details) {
      switch (opts.details.responseType) {
        case 'blob':
          parser = parseBlob;
          break;
        case 'text':
          parser = parseText;
          break;
        case 'arrayBuffer':
          parser = parseArrayBuffer;
          break;
        default:
      }

      // Add request caching.
      if (opts.details.id) {
        requestDetails = requestCache[opts.details.id];

        if (requestDetails) {
          // Handle side effects.
          if (opts.details.rule === requestRules.TAKE_LAST) {
            // Only the last requested response of the same id will be handled.
            // Otherwise, an abort error of code 20 will be thrown.
            if (requestDetails.controller) {
              requestDetails.controller.abort();
            }

            requestDetails.controller = new AbortController();
            options.signal = requestDetails.controller.signal;
            requestDetails.fetchPromise = fetch(url, options);
          }
        } else {
          // Only the last requested response of the same id will be handled.
          // Gives future ability to use the id for debugging features.
          const controller = new AbortController();
          options.signal = controller.signal;
          requestDetails = requestCache[opts.details.id] = {
            controller,
            fetchPromise: fetch(url, options)
          };
        }

        fetchPromise = requestDetails.fetchPromise;
      }
    }
  }

  // Handle standard fetching.
  if (!fetchPromise) {
    fetchPromise = fetch(url, options);
  }

  const response = await fetchPromise;
  const responseStatusChecked = await checkStatus(response);
  const responseParsed = await parser(responseStatusChecked);

  if (requestDetails) {
    requestDetails.controller = null;
    requestDetails.fetchPromise = null;
  }

  return responseParsed;
}

export function setRequestConfig(opts) {
  if (opts) {
    requestConfig = {
      ...requestConfig,
      ...opts
    };
  }
}
