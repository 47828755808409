import React from 'react';
import ClearIndicatorIcon from '/media/icons/fa/svg/solid/times-circle.svg';

import './style.css';

function SelectDropdownClearIndicator(props) {
  const className = 'r-b-select-dropdown-clear-indicator';

  function handleClick(event) {
    props.innerProps.onMouseDown(event);
  }

  // eslint-disable-next-line react/jsx-no-bind
  return <ClearIndicatorIcon className={className} onMouseDown={handleClick} />;
}

export default SelectDropdownClearIndicator;
