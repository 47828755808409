import React, { useCallback, useEffect, useState } from 'react';

import './style.css';
import LabeledSwitch from '../LabeledSwitch/component';
import { Days, ImportExport, TimeType, toDisplayName } from '../../consumer';

export interface DayWindowSwitchProps {
  day: Days;
  importExport: ImportExport;
  enabled: boolean;
  invalidDate: boolean;
  onCheck: (day: Days, importExport: ImportExport) => void;
  onChange: (updatedValue: string, day: Days, importExport: ImportExport, timeType: TimeType) => void;
  openTimeValue?: string;
  closeTimeValue?: string;
}

function DayWindowSwitch({
  day,
  importExport,
  enabled,
  onCheck,
  onChange,
  openTimeValue,
  closeTimeValue,
  invalidDate
}: DayWindowSwitchProps) {
  const baseClassName = 'day-window-switch';

  const handleChangeOpenTime = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      onChange(event.target.value, day, importExport, TimeType.OPEN);
    },
    [day, importExport, onChange]
  );

  const handleChangeCloseTime = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      onChange(event.target.value, day, importExport, TimeType.CLOSE);
    },
    [day, importExport, onChange]
  );

  const handleCheck = useCallback(
    (_checked: boolean, event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent>, _id: string) => {
      event.preventDefault();
      onCheck(day, importExport);
    },
    [onCheck, day, importExport]
  );

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}-wrapper`}>
        <LabeledSwitch
          onCheck={handleCheck}
          label={toDisplayName(day)}
          checked={enabled}
          className={`${baseClassName}__${day}-switch`}
        />
      </div>
      {enabled ? (
        <div>
          <div className={`${baseClassName}__time-selector-container`}>
            <div className={`${baseClassName}__time-selector-module`}>
              <div className={`${baseClassName}__time-selector-module-label`}>Open</div>
              <input
                type="time"
                className={`${baseClassName}__time-input`}
                onChange={handleChangeOpenTime}
                value={openTimeValue}
              />
            </div>
            <div>
              <div className={`${baseClassName}__time-selector-module`}>
                <div className={`${baseClassName}__time-selector-module-label`}>Close</div>
                <input
                  type="time"
                  className={`${baseClassName}__time-input${invalidDate ? '-invalid' : ''}`}
                  onChange={handleChangeCloseTime}
                  value={closeTimeValue}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DayWindowSwitch;
