import React from 'react';
import ClearIndicatorIcon from '/media/icons/fa/svg/solid/chevron-down.svg';

import './style.css';

function SelectDropdownIndicators() {
  const className = 'r-b-select-dropdown-indicators';

  return <ClearIndicatorIcon className={className} />;
}

export default SelectDropdownIndicators;
