import React, { useEffect } from 'react';
import CreateOrderForm from './components/CreateOrderForm';

import './style.css';

function CreateOrderPage() {
  const baseClassName = 'create-order-page';

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header`}>
        <div>Create Order</div>
      </div>
      <div className={`${baseClassName}__form-container`}>
        <CreateOrderForm />
      </div>
    </div>
  );
}

export default CreateOrderPage;
