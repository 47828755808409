import React from 'react';

import './style.css';

interface ErrorMessageProps {
  messages: string[];
}

function ErrorMessage({ messages }: ErrorMessageProps) {
  return (
    <div className="error-message">
      {messages.map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="error-message__text">
          {message}
        </div>
      ))}
    </div>
  );
}

export default ErrorMessage;
