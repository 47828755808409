import React from 'react';
import Checkbox from '/app/components/shared/Checkbox';

import './style.css';

function SelectDropdownMultiOption(props) {
  const baseClassName = 'r-b-select-dropdown-multi-option';
  let className = baseClassName;

  if (props.isSelected) {
    className += ` ${baseClassName}--selected`;
  }

  const handleClick = () => {
    props.innerProps.onClick(props.innerProps.id);
  };

  return (
    <div className={className} onClick={handleClick}>
      <Checkbox
        id={props.value}
        className={`${baseClassName}__checkbox`}
        checked={props.isSelected}
        label={props.label}
        onToggle={handleClick}
      />
    </div>
  );
}

export default SelectDropdownMultiOption;
