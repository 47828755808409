import React, { useCallback, useState } from 'react';
import TrashIcon from '/media/icons/fa/svg/regular/trash.svg';

import './style.css';

interface Props {
  emails?: string[];
  subTitle: string;
  handleEmailChange: (value: string, index: number) => any;
  handleEmailRemove: (index: number) => any;
  handleEmailAdd: () => any;
}

function EmailAddresses({ emails, subTitle, handleEmailChange, handleEmailRemove, handleEmailAdd }: Props) {
  const baseClassName = 'email-addresses';

  const addNewEmailClass = [`${baseClassName}__add-new-email`];

  if ((emails && emails.length > 1) || (emails && emails[0].length > 0)) {
    addNewEmailClass.push(`${baseClassName}__add-new-email-offset`);
  }

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__title`}>
        Email Addresses
        <div className={`${baseClassName}__sub-title`}>{subTitle}</div>
      </div>
      <div className={`${baseClassName}__input-container`}>
        {emails?.map((email, index) => {
          let showTrashIcon = index > 0;

          if (index === 0 && email?.length > 0) {
            showTrashIcon = true;
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className={`${baseClassName}__input-row`} key={`${baseClassName}-${index}`}>
              <input
                type="text"
                placeholder="Email address"
                value={email}
                onChange={(e) => {
                  handleEmailChange(e.target.value, index);
                }}
              />

              {showTrashIcon && (
                <TrashIcon
                  onClick={() => {
                    handleEmailRemove(index);
                  }}
                  className={`${baseClassName}__icon`}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className={addNewEmailClass.join(' ')} onClick={handleEmailAdd}>
        &#43; Add New
      </div>
    </div>
  );
}

export default EmailAddresses;
