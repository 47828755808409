import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../../../shared/Button';
import SelectRow from './components/SelectRow';
import { getDepartmentDisplayLabelWithAddress } from '../../../../../../../utils/resources/department';
import { SelfServiceQuote, generateQuote } from '../../../../../../../actions/self-service-quotes';
import { Department } from '../../../../../TrackingOrderDetailPage/components/TrackingOrderDetailEventCard/component';

import './style.css';
import { ToastPosition, raiseToast } from '/app/components/shared/Toaster';
import SystemToast from '/app/components/shared/SystemToast/component';

const TBD_DEPARTMENT = '7b1d3e6d-37b9-ce73-2245-5994a51cabf5';

const DELIVERY_TYPE_DROPDOWN_OPTIONS = [
  { value: 'droppick', label: 'Drop & Pick' },
  { value: 'drop_only', label: 'Drop w/o Backhaul' },
  { value: 'live', label: 'Live' }
];

const IMPORT_EXPORT_DROPDOWN_OPTIONS = [
  { value: 'import', label: 'Import' },
  { value: 'export', label: 'Export' }
];

interface SelectRowState {
  value: { value: string; label: string } | null;
}

interface CreateQuoteProps {
  consigneeDepartments?: Department[];
  onQuoteGenerated: (quote: SelfServiceQuote) => void;
}

function CreateQuote({ consigneeDepartments, onQuoteGenerated }: CreateQuoteProps) {
  const baseClassName = 'quote';

  const [lane, setLane] = useState<SelectRowState>({ value: null });
  const [jobType, setJobType] = useState<SelectRowState>({ value: DELIVERY_TYPE_DROPDOWN_OPTIONS[0] });
  const [importExport, setImportExport] = useState<SelectRowState>({ value: IMPORT_EXPORT_DROPDOWN_OPTIONS[0] });

  const [generatingQuote, setGeneratingQuote] = useState<boolean>(false);

  const canGenerateQuote = lane.value && jobType.value;

  const resetForm = () => {
    setLane({ value: null });
    setJobType({ value: DELIVERY_TYPE_DROPDOWN_OPTIONS[0] });
    setImportExport({ value: IMPORT_EXPORT_DROPDOWN_OPTIONS[0] });
  };

  const handleGenerateQuote = async () => {
    setGeneratingQuote(true);
    try {
      const res = await generateQuote({
        originDepartmentUuid: TBD_DEPARTMENT,
        destinationDepartmentUuid: lane.value!.value,
        deliveryType: jobType.value!.value,
        importExport: importExport.value!.value
      });

      onQuoteGenerated(res);
      setGeneratingQuote(false);
      resetForm();
    } catch (error) {
      setGeneratingQuote(false);
      raiseToast(
        <SystemToast
          type="error"
          message="Could not generate quote for this lane.  Please try again later or contact support is the issue persists."
        />,
        {
          position: ToastPosition.BOTTOM_RIGHT
        }
      );
    }
  };

  const laneDropdownOptions = useMemo(() => {
    if (!consigneeDepartments) {
      return [];
    }

    return consigneeDepartments.map((department) => ({
      value: department.uuid,
      label: getDepartmentDisplayLabelWithAddress(department)
    }));
  }, [consigneeDepartments]);

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__title`}>Create Quote</div>
      <div>
        <SelectRow
          label="Lane"
          options={laneDropdownOptions}
          onChange={(value) => setLane({ value })}
          value={lane.value}
        />
        <div className={`${baseClassName}__newCnee`}>
          <Link to="/dashboard/self-service/consignee-creation">Create New Consignee</Link>
        </div>
        <SelectRow
          label="Job Type"
          options={DELIVERY_TYPE_DROPDOWN_OPTIONS}
          onChange={(value) => setJobType({ value })}
          value={jobType.value}
        />
        <SelectRow
          label="Import/Export"
          options={IMPORT_EXPORT_DROPDOWN_OPTIONS}
          onChange={(value) => setImportExport({ value })}
          value={importExport.value}
        />
        <Button
          className={`${baseClassName}__button`}
          label="Generate Quote"
          testId="GenerateQuoteButton"
          color="secondary"
          loading={generatingQuote}
          disabled={!canGenerateQuote}
          onClick={handleGenerateQuote}
        />
      </div>
    </div>
  );
}

export default CreateQuote;
