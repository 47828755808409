import request from '/app/utils/request';
import { api } from '/app/utils/url';

const resourceType = 'departments';

export function retrieveDepartments() {
  return async () => {
    try {
      const url = api(`/${resourceType}`);
      const options = {
        headers: {
          Accept: 'application/vnd.da.raw+json'
        }
      };
      const response = await request(url, options);
      const { data } = response;
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function retrieveDepartmentsByType(type, query?) {
  const excludeSharedDepartments = type === 'limited_consignee';
  if (excludeSharedDepartments) {
    type = 'consignee';
  }

  return async (appStateEvent) => {
    try {
      appStateEvent.dispatch(`RETRIEVE_${type.toUpperCase()}_DEPARTMENTS`, {
        path: `departments:${type}:loading`,
        data: true
      });

      let requestQuery = {
        type,
        sort: 'name',
        excludeShared: excludeSharedDepartments
      };

      if (query) {
        requestQuery = {
          ...requestQuery,
          ...query
        };
      }

      const url = api(`/v2/${resourceType}`);
      const options = {
        headers: {
          Accept: 'application/vnd.da.raw+json'
        },
        query: requestQuery
      };
      const response = await request(url, options);
      const { data } = response;

      appStateEvent.dispatch(`RETRIEVE_${type.toUpperCase()}_DEPARTMENTS_SUCCESS`, {
        path: `departments:${type}`,
        data
      });

      return data;
    } catch (err) {
      appStateEvent.dispatch(`RETRIEVE_${type.toUpperCase()}_DEPARTMENTS_ERROR`, {
        path: `departments:${type}`,
        data: err
      });

      throw err;
    } finally {
      appStateEvent.dispatch(`RETRIEVE_${type.toUpperCase()}_DEPARTMENTS_FINISHED`, {
        path: `departments:${type}:loading`,
        data: false
      });
    }
  };
}
