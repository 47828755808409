import React, { useState, useEffect, useLayoutEffect, ReactElement, KeyboardEvent, MouseEvent } from 'react';

import './style.css';

interface ModalProps {
  className?: string;
  children?: ReactElement | ReactElement[];
  fullscreen?: boolean;
  open?: boolean;
  disableClose?: boolean;
  disableParentScroll?: boolean;
  onClose?: any;
}

function Modal(props: ModalProps): ReactElement | null {
  const [open, setOpen] = useState(props.open);
  const [clicked, setClicked] = useState(props.open);

  const baseClassName = 'r-b-modal';
  let className = baseClassName;

  if (props.fullscreen) {
    className += ' r-b-modal--fullscreen';
  }

  if (open) {
    className += ' r-b-modal--show';
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    if (!props.disableClose) {
      setOpen(false);
      setClicked(true);
    }
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    if (!props.disableClose) {
      if (event.key === 'Escape') {
        setOpen(false);
      }
    }
  };

  const disableParentScroll = (disable: Boolean): void => {
    if (disable) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100% !important';
    } else {
      document.body.style.overflow = '';
      document.body.style.height = '';
    }
  };

  useEffect(() => {
    setOpen(!!props.open);

    return () => {
      disableParentScroll(false);
    };
  }, [props.open]);

  useEffect(() => {
    if (!props.disableClose && clicked === true && !open && props.onClose) {
      props.onClose();
    }
  }, [open, props.disableClose]);

  useEffect(() => {
    if (open && props.disableParentScroll) {
      disableParentScroll(true);
    } else {
      disableParentScroll(false);
    }
  }, [open, props.disableParentScroll]);

  if (!props.open) {
    return null;
  }

  return (
    <div className={className} role="presentation" tabIndex={-1} onKeyDown={handleKeyDown}>
      {!props.disableClose && (
        <button className="r-b-modal__button" type="button" onClick={handleClick} aria-label="Close Modal">
          X
        </button>
      )}
      {props.children}
    </div>
  );
}

export default Modal;
