import request from '/app/utils/request';
import {
  clearAuthTokens,
  getJWT,
  setImpersonateToken,
  getHUBServiceAuthToken,
  getDepartment,
  deleteImpersonateToken
} from '/app/utils/auth';
import { api } from '/app/utils/url';
import createEnvHostFromHostname from '/app/utils/create-env-host-from-hostname';

/**
 * Use to get reset password email.
 *
 * @param email Email address of the account.
 */
export function forgotPasswordRequest(email: string) {
  return async () => {
    try {
      const payload = {
        meta: {
          action: 'password_reset_request'
        },
        data: {
          email1: email
        }
      };

      const options = {
        method: 'PATCH',
        body: JSON.stringify(payload)
      };

      await request(api('/user'), options);
    } catch (error) {
      throw error;
    }
  };
}

/**
 * Use to reset password.
 *
 * @param resetToken Reset token from email.
 * @param password New password to change to.
 */
export function resetPasswordRequest(resetToken: string, password: string) {
  return async () => {
    try {
      const payload = {
        meta: {
          action: 'reset_password'
        },
        data: [
          {
            token: resetToken,
            newPassword: password
          }
        ]
      };
      const options = {
        method: 'PATCH',
        credentials: 'same-origin',
        body: JSON.stringify(payload)
      };
      await request(api('/user'), options);
    } catch (error) {
      throw error;
    }
  };
}

export function login(email, password) {
  return async (appStateEvent) => {
    try {
      const authHeader = btoa(`${email}:${password}`);
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Basic ${authHeader}`
        }
      };

      const result = await request(api('/login'), options);
      const hubServiceAuthToken = getHUBServiceAuthToken();
      const jwt = getJWT(hubServiceAuthToken);
      const department = getDepartment(jwt);

      appStateEvent.dispatchBatch('LOGIN_SUCCEEDED', [
        {
          path: 'auth',
          data: {
            jwt,
            hubServiceAuthToken
          }
        },
        {
          path: 'userDepartment',
          data: department
        }
      ]);

      return result;
    } catch (err) {
      throw err;
    }
  };
}

export function refresh() {
  return async (appStateEvent) => {
    try {
      const options = {
        method: 'POST'
      };

      const result = await request(api('/refresh'), options);
      const hubServiceAuthToken = getHUBServiceAuthToken();
      const jwt = getJWT(hubServiceAuthToken);
      const department = getDepartment(jwt);

      appStateEvent.dispatchBatch('LOGIN_SUCCEEDED', [
        {
          path: 'auth',
          data: {
            jwt,
            hubServiceAuthToken
          }
        },
        {
          path: 'userDepartment',
          data: department
        }
      ]);

      return result;
    } catch (err) {
      throw err;
    }
  };
}

export function logout() {
  return () => {
    clearAuthTokens();

    window.location.href = createEnvHostFromHostname(window.location.hostname, 'accounts', 9002);

    // appStateEvent.dispatchBatch('LOGOUT_SUCCEEDED', [
    //   {
    //     path: 'auth',
    //     data: null
    //   },
    //   {
    //     path: 'userDepartment',
    //     data: null
    //   }
    // ]);
  };
}

export function impersonateUser(id: string) {
  return async (appStateEvent) => {
    try {
      appStateEvent.dispatch('IMPERSONATE_USER_FINISHED', {
        path: 'user:loading',
        data: true
      });

      const payload = {
        meta: {
          action: 'impersonate'
        },
        data: [
          {
            id,
            type: 'user'
          }
        ]
      };

      const options = {
        method: 'POST',
        body: JSON.stringify(payload)
      };

      const result = await request(api('/v2/users'), options);
      const { authToken } = result;
      setImpersonateToken(authToken);
      const jwt = getJWT(authToken);
      const department = getDepartment(jwt);

      appStateEvent.dispatchBatch('LOGIN_SUCCEEDED', [
        {
          path: 'auth',
          data: {
            jwt,
            authToken
          }
        },
        {
          path: 'auth:impersonating',
          data: true
        },
        {
          path: 'userDepartment',
          data: department
        }
      ]);

      return result;
    } catch (err) {
      throw err;
    } finally {
      appStateEvent.dispatch('IMPERSONATE_USER_FINISHED', {
        path: 'user:loading',
        data: false
      });
    }
  };
}

export function stopImpersonateUser() {
  return async (appStateEvent) => {
    try {
      deleteImpersonateToken();

      const hubServiceAuthToken = getHUBServiceAuthToken();
      const jwt = getJWT(hubServiceAuthToken);
      const department = getDepartment(jwt);

      appStateEvent.dispatchBatch('STOP_IMPERSONATE_USER_SUCCEEDED', [
        {
          path: 'auth',
          data: {
            jwt,
            hubServiceAuthToken
          }
        },
        {
          path: 'auth:impersonating',
          data: false
        },
        {
          path: 'userDepartment',
          data: department
        }
      ]);

      window.location.reload();
    } catch (err) {
      appStateEvent.dispatch('IMPERSONATE_USER_ERROR', {
        data: err
      });
      throw err;
    } finally {
      appStateEvent.dispatch('IMPERSONATE_USER_FINISHED', {
        data: false
      });
    }
  };
}
