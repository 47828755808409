import React from 'react';
import { ImportExportHours } from '../components/pages/SelfServicePage/components/ConsigneeCreationPage/consumer';
import SystemToast from '../components/shared/SystemToast/component';
import { ToastPosition, raiseToast } from '../components/shared/Toaster';
import request, { isFetchCanceled } from '../utils/request';
import { api } from '../utils/url';

interface CreateConsigneeParams {
  name: string;
  nickname: string;
  shippingStreet1: string;
  shippingStreet2: string;
  shippingCity: string;
  shippingState: string;
  shippingZipCode: string;
  shippingCountry: string;
  shippingLat: number | undefined;
  shippingLng: number | undefined;
  timezone: string;
  hours: {
    openTwentyFourHours: boolean;
    sameWeekdayHours: boolean;
    apptNeeded: boolean;
    import: ImportExportHours;
    export: ImportExportHours;
  };
}

const resourceType = 'v2/departments';

export function createConsignee(params: CreateConsigneeParams) {
  return async (appStateEvent: any) => {
    try {
      const url = api(`/${resourceType}/shipper-create-consignee`);
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/vnd.da.raw+json'
        },
        body: JSON.stringify(params)
      };
      const response = await request(url, options);

      appStateEvent.dispatch('CONSIGNEE_CREATED', {
        path: 'consignee',
        data: response?.body
      });
    } catch (error) {
      appStateEvent.dispatch('CONSIGNEE_CREATE_ERROR');

      if (!isFetchCanceled(error)) {
        raiseToast(<SystemToast type="error" message={(error as Error).message} />, {
          position: ToastPosition.BOTTOM_RIGHT
        });
      }

      throw error;
    }
  };
}
