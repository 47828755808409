import request from '../utils/request';
import { api } from '../utils/url';

export interface SelfServiceQuote {
  uuid: string;
  drayCharge: number;
  fuelSurcharge: number;
  totalCost: number;
  distance: number;
  expiredAt: string;
  userUuid: string;
  userCompanyUuid: string;
  originDepartmentUuid: string;
  destinationDepartmentUuid: string;
  forecastChassisDays: number;
  forecastStorageDays: number;
  forecastPrePullDays: number;
  forecastChassisCostPerDay: number;
  forecastStorageCostPerDay: number;
  forecastPrePullCostPerDay: number;
  createdAt: string;
  updatedAt: string;
}

export async function fetchQuotes(): Promise<SelfServiceQuote[]> {
  try {
    const url = api('/self-service-quote');
    const res = await request<{ data: SelfServiceQuote[] }>(url);

    return res?.data || [];
  } catch (err) {
    throw err;
  }
}

export async function generateQuote({
  originDepartmentUuid,
  destinationDepartmentUuid,
  deliveryType,
  importExport
}: {
  originDepartmentUuid: string;
  destinationDepartmentUuid: string;
  deliveryType: string;
  importExport: string;
}): Promise<SelfServiceQuote> {
  try {
    const url = api('/self-service-quote');
    const res = await request(url, {
      method: 'POST',
      body: JSON.stringify({
        originDepartmentUuid,
        destinationDepartmentUuid,
        deliveryType,
        importExport
      })
    });

    return res;
  } catch (err) {
    throw err;
  }
}

export async function expireQuote({ quoteId }: { quoteId: string }): Promise<string> {
  try {
    const url = api(`/self-service-quote/${quoteId}/expire`);
    const res = await request(url, {
      method: 'POST',
      body: JSON.stringify({
        quoteId
      })
    });

    return res;
  } catch (err) {
    throw err;
  }
}
