import request from '../utils/request';
import { api } from '../utils/url';

export async function fetchAnalyticsLinks() {
  try {
    const url = api('/shipper-dash-analytics-links');
    const res = await request(url);

    return res;
  } catch (err) {
    throw err;
  }
}
