import amp from 'amplitude-js';
import { Env, getEnvFromHostname } from '@drayalliance/utils';
import {
  getJWT,
  getHUBServiceAuthToken,
  getRawHUBServiceImpersonateAuthToken,
  getRawHUBServiceAuthToken
} from './auth';

export function createEventProperties(screen: string, category: string, data: any) {
  const hubServiceAuthToken = getHUBServiceAuthToken();
  const jwt = getJWT(hubServiceAuthToken);
  const impersonationToken = getRawHUBServiceImpersonateAuthToken();
  let impersonationUuid: string | null = null;
  let impersonationName: string | null = null;

  if (impersonationToken) {
    impersonationUuid = jwt?.uuid || null;
    impersonationName = `${jwt?.firstName} ${jwt?.lastName}`;
  }

  return {
    ...data,
    screen, // (ie. "dispatch", "settings", "reports")
    category, // (ie. "page view", "click", "success", "error")
    // component (ie. "bundle/sidebar", "settings/people") optional for now
    impersonating: impersonationName,
    impersonationUuid
  };
}

/**
 * Create user properties based on the raw hub service token
 */
export function createUserProperties() {
  const rawHubServiceAuthToken = getRawHUBServiceAuthToken();
  const decodedHubServiceAuthToken = atob(rawHubServiceAuthToken!);
  const impersonationToken = getRawHUBServiceImpersonateAuthToken();

  const jwt = getJWT(decodedHubServiceAuthToken);

  const department = jwt?.departments?.[0];

  if (jwt || !impersonationToken) {
    return {
      email: jwt?.email1,
      firstName: jwt?.firstName,
      lastName: jwt?.lastName,
      departmentName: department?.name,
      departmentType: department?.type,
      departmentUuid: department?.uuid,
      departmentCompanyUuid: department?.companyUuid
    };
  }
}

export function initAnalytics(userId?: string) {
  const hubServiceAuthToken = getHUBServiceAuthToken();
  userId = userId || getJWT(hubServiceAuthToken)?.uuid;
  const currEnv = getEnvFromHostname(window.location.hostname);
  // prod
  let apiKey = 'e53ae55bc17b27d3779c2226e62692f6';

  if (currEnv.env !== Env.Production) {
    // test
    apiKey = '7954da5e7e341d1261bd43532c395013';
  }

  if (userId) {
    amp.getInstance().init(apiKey, userId);
  } else {
    amp.getInstance().init(apiKey);
  }
}

export const logEvent = (event: string, screen: string, category: string, ep = {}) => {
  event = event.toLowerCase();
  category = category.toLowerCase();
  const eventName = `${category}: ${event}`;
  const eventProperties = createEventProperties(screen, category, ep);
  amp.getInstance().logEvent(eventName, eventProperties);
};

export const logAnalyticsError = (screen: string, ep = {}) => {
  logEvent(screen, screen, 'error', ep);
};

export const logAnalyticsSuccess = (screen: string, ep = {}) => {
  logEvent(screen, screen, 'success', ep);
};

export const logAnalyticsPageView = (screen: string, ep = {}) => {
  logEvent(screen, screen, 'page view', ep);
};

export const logAnalyticsPageLoad = (screen: string, ep = {}) => {
  logEvent(screen, screen, 'page load', ep);
};

export const logAnalyticsClick = (action: string, screen: string, ep = {}) => {
  logEvent(action, screen, 'click', ep);
};

export function setAnalyticsUserId(id: string) {
  amp.getInstance().setUserId(id);
}

export function setAnalyticsUserProperties() {
  const userProperties = createUserProperties();
  if (userProperties) {
    amp.getInstance().setUserProperties(userProperties);
  }
}
