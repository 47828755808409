import React from 'react';
import TimesIcon from '/media/icons/fa/svg/light/times.svg';

import './style.css';

interface SystemHeaderProps {
  className?: string;
  name?: string;
  departmentName?: string;
  onStopImpersonate: () => void;
}

function SystemHeader(props: SystemHeaderProps) {
  const baseClassName = 'app-system-header';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className}>
      <div className={`${baseClassName}__close-btn`} onClick={props.onStopImpersonate}>
        <TimesIcon />
      </div>
      <div className={`${baseClassName}__body`}>
        Impersonating:<span className={`${baseClassName}__body-name`}>{props.name}</span>
        <span>@</span>
        <span className={`${baseClassName}__body-name`}>{props.departmentName}</span>
      </div>
    </div>
  );
}

export default SystemHeader;
