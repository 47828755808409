const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export default function isValidEmail(value: string): boolean {
  if (EMAIL_REGEX.test(value)) {
    return true;
  }
  return false;
}

export const validateEmailAddresses = (addresses: string[]): boolean => {
  if (addresses.length === 0) {
    return true;
  }

  return addresses.every(isValidEmail);
};
