import React from 'react';
import ListItem from './components/ListItem';
import { Department } from '/app/components/pages/TrackingOrderDetailPage/components/TrackingOrderDetailEventCard/component';
import { SelfServiceQuote } from '/app/actions/self-service-quotes';

import './style.css';

interface QuoteListProps {
  departmentsById: Record<string, Department>;
  quotes: SelfServiceQuote[];
  loading: boolean;
}

function QuoteList({ departmentsById, quotes, loading }: QuoteListProps) {
  const baseClassName = 'quote-list';

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__title-container`}>
        <div className={`${baseClassName}__title-main`}>Active Quotes</div>
        <div className={`${baseClassName}__title-info`}>
          (
          <a href="https://www.drayalliance.com/shipper-accessorials" target="_blank" rel="noopener noreferrer">
            Accessorial List
          </a>
          )
        </div>
      </div>
      <div className={`${baseClassName}__list-container`}>
        {loading && <div>Loading...</div>}
        {quotes.length === 0 && !loading && <div>No valid quotes found!</div>}
        {quotes.map((quote) => (
          <ListItem key={quote.uuid} quote={quote} departmentsById={departmentsById} />
        ))}
      </div>
    </div>
  );
}

export default QuoteList;
