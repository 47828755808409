import { LDInspection, LDEvaluationDetail } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { User } from '@datadog/browser-core';
import { Env } from '@drayalliance/utils';
import manifest from '../manifest.json';

export const initRum = (env?: Env) => {
  if (env && env !== Env.Local) {
    const coimmitHash = manifest?.commitHash || '';

    datadogRum.init({
      applicationId: '97f6ed9d-6993-4d89-8ce6-fb4acc83a5a7',
      clientToken: 'pubfc24738e875bd87515b5fbfc415f72ae',
      site: 'datadoghq.com',
      service: 'shipper-dashboard',
      env: env.toString(),
      version: coimmitHash.slice(0, 8),
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['feature_flags']
    });

    datadogRum.startSessionReplayRecording();
  }
};

export const setDatadogUser = (user: User) => {
  datadogRum.setUser(user);
};

// eslint-disable-next-line arrow-body-style
export const datadogInspector = (): LDInspection => ({
  type: 'flag-used',
  name: 'dd-inspector',
  method: (key: string, detail: LDEvaluationDetail) => {
    datadogRum.addFeatureFlagEvaluation(key, detail.value);
  }
});
