import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Component from './component';
import { useAppStateDispatcher } from '/lib/react-app-state';
import { createConsignee } from '/app/actions/consignee';
import { api } from '/app/utils/url';
import { raiseToast } from '/app/components/shared/Toaster';
import SystemToast from '/app/components/shared/SystemToast/component';
import request from '/app/utils/request';

export enum Days {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  WEEKDAYS = 'weekdays'
}

export function toDisplayName(day: Days): string {
  switch (day) {
    case Days.SUNDAY:
      return 'Sunday';
    case Days.MONDAY:
      return 'Monday';
    case Days.TUESDAY:
      return 'Tuesday';
    case Days.WEDNESDAY:
      return 'Wednesday';
    case Days.THURSDAY:
      return 'Thursday';
    case Days.FRIDAY:
      return 'Friday';
    case Days.SATURDAY:
      return 'Saturday';
    case Days.WEEKDAYS:
      return 'Weekdays';
    default:
      throw new Error(`Invalid day: ${day}`);
  }
}

export function orderedDays(): Days[] {
  return [Days.SUNDAY, Days.MONDAY, Days.TUESDAY, Days.WEDNESDAY, Days.THURSDAY, Days.FRIDAY, Days.SATURDAY];
}

export enum ImportExport {
  IMPORT = 'import',
  EXPORT = 'export'
}

export enum TimeType {
  OPEN = 'hourOpen',
  CLOSE = 'hourClose'
}

export type ImportExportHours = {
  [key in Exclude<Days, Days.WEEKDAYS>]?: {
    [key in TimeType]?: string | null;
  };
};

export type ImportExportHoursInvalid = {
  [key in ImportExport]: {
    [key in Exclude<Days, Days.WEEKDAYS>]?: {
      invalid: boolean;
    };
  };
};

function ConsigneeCreationPageConsumer() {
  const [facility247, setFacility247] = useState(true);
  const [sameImportExport, setSameImportExport] = useState(false);
  const [sameWeekdayHours, setSameWeekdayHours] = useState(false);
  const [apptNeeded, setApptNeeded] = useState(false);
  const [sopOperationalHoursImport, setSOPOperationalInfoImport] = useState<ImportExportHours>({});
  const [sopOperationalHoursExport, setSOPOperationalInfoExport] = useState<ImportExportHours>({});
  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeNickname, setConsigneeNickname] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState<{
    place: google.maps.places.PlaceResult | null;
    inputVal: string;
  }>({
    place: null,
    inputVal: ''
  });

  const toggle247Facility = useCallback(() => {
    setFacility247(() => !facility247);
    setSOPOperationalInfoImport({});
    setSOPOperationalInfoExport({});
  }, [facility247]);

  const toggleSOPTimeSwitch = useCallback(
    (day: Days, importExport: ImportExport) => {
      if (day === Days.WEEKDAYS) {
        if (importExport === ImportExport.IMPORT) {
          setSOPOperationalInfoImport((prev) => ({
            ...prev,
            [Days.MONDAY]: prev[Days.MONDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined,
            [Days.TUESDAY]: prev[Days.TUESDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined,
            [Days.WEDNESDAY]: prev[Days.WEDNESDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined,
            [Days.THURSDAY]: prev[Days.THURSDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined,
            [Days.FRIDAY]: prev[Days.FRIDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined
          }));
        } else {
          setSOPOperationalInfoExport((prev) => ({
            ...prev,
            [Days.MONDAY]: prev[Days.MONDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined,
            [Days.TUESDAY]: prev[Days.TUESDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined,
            [Days.WEDNESDAY]: prev[Days.WEDNESDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined,
            [Days.THURSDAY]: prev[Days.THURSDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined,
            [Days.FRIDAY]: prev[Days.FRIDAY] === undefined ? { hourOpen: null, hourClose: null } : undefined
          }));
        }
      } else if (importExport === ImportExport.IMPORT) {
        setSOPOperationalInfoImport((prev) => {
          if (prev[day] !== undefined) {
            return {
              ...prev,
              [day]: undefined
            };
          }
          return {
            ...prev,
            [day]: {
              hourOpen: null,
              hourClose: null
            }
          };
        });
      } else {
        setSOPOperationalInfoExport((prev) => {
          if (prev[day] !== undefined) {
            return {
              ...prev,
              [day]: undefined
            };
          }
          return {
            ...prev,
            [day]: {
              hourOpen: null,
              hourClose: null
            }
          };
        });
      }

      if (sameImportExport) {
        setSOPOperationalInfoExport(sopOperationalHoursImport);
      }
    },
    [sameImportExport, sopOperationalHoursImport, sopOperationalHoursExport]
  );

  const updateOpenCloseTime = useCallback(
    (updatedTime: string, day: Days, importExport: ImportExport, timeType: TimeType) => {
      if (day === Days.WEEKDAYS) {
        if (importExport === ImportExport.IMPORT) {
          setSOPOperationalInfoImport((prev) => ({
            ...prev,
            [Days.MONDAY]: {
              ...prev[Days.MONDAY],
              [timeType]: updatedTime
            },
            [Days.TUESDAY]: {
              ...prev[Days.TUESDAY],
              [timeType]: updatedTime
            },
            [Days.WEDNESDAY]: {
              ...prev[Days.WEDNESDAY],
              [timeType]: updatedTime
            },
            [Days.THURSDAY]: {
              ...prev[Days.THURSDAY],
              [timeType]: updatedTime
            },
            [Days.FRIDAY]: {
              ...prev[Days.FRIDAY],
              [timeType]: updatedTime
            }
          }));
        } else {
          setSOPOperationalInfoExport((prev) => ({
            ...prev,
            [Days.MONDAY]: {
              ...prev[Days.MONDAY],
              [timeType]: updatedTime
            },
            [Days.TUESDAY]: {
              ...prev[Days.TUESDAY],
              [timeType]: updatedTime
            },
            [Days.WEDNESDAY]: {
              ...prev[Days.WEDNESDAY],
              [timeType]: updatedTime
            },
            [Days.THURSDAY]: {
              ...prev[Days.THURSDAY],
              [timeType]: updatedTime
            },
            [Days.FRIDAY]: {
              ...prev[Days.FRIDAY],
              [timeType]: updatedTime
            }
          }));
        }
      } else if (importExport === ImportExport.IMPORT) {
        setSOPOperationalInfoImport((prev) => ({
          ...prev,
          [day]: {
            ...prev[day],
            [timeType]: updatedTime
          }
        }));
      } else {
        setSOPOperationalInfoExport((prev) => ({
          ...prev,
          [day]: {
            ...prev[day],
            [timeType]: updatedTime
          }
        }));
      }
    },
    [sopOperationalHoursImport, sopOperationalHoursExport]
  );

  const toggleSameImportExport = useCallback(() => {
    setSameImportExport(() => !sameImportExport);
    setSOPOperationalInfoExport(sopOperationalHoursImport);
  }, [sameImportExport, sopOperationalHoursImport]);

  const toggleSameWeekdayHours = useCallback(() => {
    setSameWeekdayHours(() => !sameWeekdayHours);
    setSOPOperationalInfoExport({
      ...sopOperationalHoursExport,
      [Days.MONDAY]: sopOperationalHoursExport[Days.MONDAY],
      [Days.TUESDAY]: sopOperationalHoursExport[Days.MONDAY],
      [Days.WEDNESDAY]: sopOperationalHoursExport[Days.MONDAY],
      [Days.THURSDAY]: sopOperationalHoursExport[Days.MONDAY],
      [Days.FRIDAY]: sopOperationalHoursExport[Days.MONDAY]
    });
    setSOPOperationalInfoImport({
      ...sopOperationalHoursImport,
      [Days.MONDAY]: sopOperationalHoursImport[Days.MONDAY],
      [Days.TUESDAY]: sopOperationalHoursImport[Days.MONDAY],
      [Days.WEDNESDAY]: sopOperationalHoursImport[Days.MONDAY],
      [Days.THURSDAY]: sopOperationalHoursImport[Days.MONDAY],
      [Days.FRIDAY]: sopOperationalHoursImport[Days.MONDAY]
    });
  }, [sameWeekdayHours, sopOperationalHoursImport, sopOperationalHoursExport]);

  const toggleApptNeeded = useCallback(() => {
    setApptNeeded(() => !apptNeeded);
  }, [apptNeeded]);

  const handleConsigneeNameChange = useCallback((name: string) => {
    setConsigneeName(name);
  }, []);

  const handleConsigneeNicknameChange = useCallback((nickname: string) => {
    setConsigneeNickname(nickname);
  }, []);

  const handleConsigneeAddressChange = useCallback(
    (addressData: { place: google.maps.places.PlaceResult | null; inputVal: string }) => {
      setConsigneeAddress({ place: addressData.place, inputVal: addressData.inputVal });
    },
    []
  );

  const testIfSOPOperationalHoursAreFilledOut = (importExportHours: ImportExportHours) =>
    Object.values(importExportHours).some((day) => {
      if (day === undefined) {
        return false;
      }
      return day[TimeType.OPEN] !== null && day[TimeType.CLOSE] !== null;
    });

  const invalidWarehouseHours = useMemo(() => {
    const sopOperationalHoursInvalid: ImportExportHoursInvalid = {
      [ImportExport.IMPORT]: {},
      [ImportExport.EXPORT]: {}
    };
    Object.keys(sopOperationalHoursExport).forEach((day) => {
      const openTime = sopOperationalHoursExport[day]?.hourOpen;
      const closeTime = sopOperationalHoursExport[day]?.hourClose;
      if (openTime && closeTime && openTime >= closeTime) {
        sopOperationalHoursInvalid[ImportExport.EXPORT][day as Days] = { invalid: true };
      }
    });

    Object.keys(sopOperationalHoursImport).forEach((day) => {
      const openTime = sopOperationalHoursImport[day]?.hourOpen;
      const closeTime = sopOperationalHoursImport[day]?.hourClose;
      if (openTime && closeTime && openTime >= closeTime) {
        sopOperationalHoursInvalid[ImportExport.IMPORT][day as Days] = { invalid: true };
      }
    });

    return sopOperationalHoursInvalid;
  }, [sopOperationalHoursImport, sopOperationalHoursExport]);

  const saveButtonEnabled = useMemo(() => {
    const accountInfoFilledOut =
      consigneeName.length > 0 && consigneeNickname.length > 0 && consigneeAddress.place !== null;
    const operationInfoFilledOut =
      facility247 ||
      testIfSOPOperationalHoursAreFilledOut(sopOperationalHoursImport) ||
      testIfSOPOperationalHoursAreFilledOut(sopOperationalHoursExport);
    const anyInvalidHours = Object.values(invalidWarehouseHours).some((day) =>
      Object.values(day).some((time) => time.invalid)
    );
    return accountInfoFilledOut && operationInfoFilledOut && !anyInvalidHours;
  }, [
    consigneeName,
    consigneeNickname,
    consigneeAddress,
    facility247,
    sopOperationalHoursImport,
    sopOperationalHoursExport,
    invalidWarehouseHours
  ]);

  const appStateDispatcher = useAppStateDispatcher();

  const clearForm = useCallback(() => {
    setFacility247(true);
    setSameImportExport(false);
    setSameWeekdayHours(false);
    setApptNeeded(false);
    setSOPOperationalInfoImport({});
    setSOPOperationalInfoExport({});
    setConsigneeName('');
    setConsigneeNickname('');
    setConsigneeAddress({ place: null, inputVal: '' });
  }, []);

  type ObjectFromList<T extends ReadonlyArray<string>, V = string> = {
    [K in T extends ReadonlyArray<infer U> ? U : never]: V;
  };

  type AddressComponentMap = ObjectFromList<google.maps.GeocoderAddressComponent['types'], string>;

  const getTimezoneWithLatLong = async (lat: number | undefined, long: number | undefined) => {
    try {
      if (lat !== undefined && long !== undefined) {
        const url = api(`/timezone?lat=${lat}&lng=${long}`);
        const response = await request<{ timeZoneId: string }>(url);

        return response?.timeZoneId || 'America/Los_Angeles';
      }
      throw new Error('Latitude and Longitude are required to fetch timezone.');
    } catch (err) {
      raiseToast(<SystemToast type="error" message="Unable to fetch location timezone." />);
      throw err;
    }
  };

  const onSaveClicked = useCallback(async () => {
    const addressComponents: AddressComponentMap = {};
    consigneeAddress.place?.address_components?.forEach((component) => {
      component.types.forEach((type) => {
        addressComponents[type] = component.long_name;
      });
    });

    const consigneeCreationPayload = {
      name: consigneeName,
      nickname: consigneeNickname,
      shippingStreet1: `${addressComponents.street_number} ${addressComponents.route}`,
      shippingStreet2: '',
      shippingCity: addressComponents.locality,
      shippingState: addressComponents.administrative_area_level_1,
      shippingZipCode: addressComponents.postal_code,
      shippingCountry: addressComponents.country,
      shippingLat: consigneeAddress.place?.geometry?.location?.lat(),
      shippingLng: consigneeAddress.place?.geometry?.location?.lng(),
      timezone: await getTimezoneWithLatLong(
        consigneeAddress.place?.geometry?.location?.lat(),
        consigneeAddress.place?.geometry?.location?.lng()
      ),
      hours: {
        openTwentyFourHours: facility247,
        sameWeekdayHours,
        apptNeeded,
        import: sopOperationalHoursImport,
        export: sopOperationalHoursExport
      }
    };

    await appStateDispatcher(createConsignee(consigneeCreationPayload));
    clearForm();
  }, [
    consigneeName,
    consigneeNickname,
    consigneeAddress,
    facility247,
    sameWeekdayHours,
    apptNeeded,
    sopOperationalHoursImport,
    sopOperationalHoursExport
  ]);

  return (
    <Component
      onSOPTimeSwitch={toggleSOPTimeSwitch}
      onSaveClicked={onSaveClicked}
      updateOpenCloseTime={updateOpenCloseTime}
      facility247={facility247}
      sameWeekdayHours={sameWeekdayHours}
      sameImportExport={sameImportExport}
      apptNeeded={apptNeeded}
      sopOperationalHoursImport={sopOperationalHoursImport}
      sopOperationalHoursExport={sopOperationalHoursExport}
      toggle24_7Facility={toggle247Facility}
      toggleSameImportExport={toggleSameImportExport}
      toggleSameWeekdayHours={toggleSameWeekdayHours}
      toggleApptNeeded={toggleApptNeeded}
      handleConsigneeNameChange={handleConsigneeNameChange}
      handleConsigneeNicknameChange={handleConsigneeNicknameChange}
      handleConsigneeAddressChange={handleConsigneeAddressChange}
      invalidWarehouseHours={invalidWarehouseHours}
      saveButtonEnabled={saveButtonEnabled}
      consigneeName={consigneeName}
      consigneeNickname={consigneeNickname}
      consigneeAddress={consigneeAddress}
      clearForm={clearForm}
    />
  );
}

export default ConsigneeCreationPageConsumer;
