import React from 'react';
import EmailAddresses from '../EmailAddresses';

import './style.css';

interface Props {
  emailAddresses: string[];
  onEmailChange: (value: string, index: number) => void;
  onEmailAdd: () => void;
  onEmailRemove: (index: number) => void;
}

function BetterEmpties(props: Props) {
  const baseClassName = 'better-empties';

  return (
    <div className={baseClassName}>
      <EmailAddresses
        emails={props.emailAddresses}
        subTitle="Configure the email addresses you wish to receive the Empty Ready"
        handleEmailChange={props.onEmailChange}
        handleEmailAdd={props.onEmailAdd}
        handleEmailRemove={props.onEmailRemove}
      />
    </div>
  );
}

export default BetterEmpties;
